import React from "react";
import { Route } from "react-router-dom";
import HealthCenter from "../site_components/health_center/HealthCenter";
import ContentCreation from "../site_components/content_creation/ContentCreation";
import Targeting from "../site_components/targeting/Targeting";
import CompanyInfo from "../site_components/company_info/CompanyInfo";
import MediaLibrary from "../site_components/media_library/MediaLibrary";
import SiteSettings from "../site_components/settings/SiteSettings";

export default function SiteRoutes() {
  return (
    <>
      <Route exact path="/site/:siteId/" component={HealthCenter} />
      <Route exact path="/site/:siteId/health/" component={HealthCenter} />
      <Route exact path="/site/:siteId/create" component={ContentCreation} />
      <Route exact path="/site/:siteId/targeting" component={Targeting} />
      <Route exact path="/site/:siteId/company" component={CompanyInfo} />
      <Route exact path="/site/:siteId/library" component={MediaLibrary} />
      <Route exact path="/site/:siteId/settings" component={SiteSettings} />
    </>
  );
}
