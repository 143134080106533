import {
  BusinessRounded,
  KeyboardArrowDownRounded,
  Save,
} from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  selectFieldProps,
  companySizes,
  industriesTemp,
} from "../../../../shared/constants";
import { useDb } from "../../../../../contexts/DatabaseContext";

export default function Firmographics({ siteId }) {
  const { setStandardDoc, getStandardDoc } = useDb();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [updated, setUpdated] = useState(false);
  const industryOptions = industriesTemp.map((industry, index) => ({
    value: industry.name,
    label: industry.name,
    key: index,
  }));

  const handleIndustryChange = async (val) => {
    setTargetProps({
      ...targetProps,
      industries: val,
    });
    setUpdated(true);
  };

  const handleIndustryExcludeChange = async (val) => {
    setTargetProps({
      ...targetProps,
      excludeIndustries: val,
    });
    setUpdated(true);
  };

  const [targetProps, setTargetProps] = useState({
    employeeSize: [{ value: "0-10", label: "11-20" }],

    revenueRange: [0, 20],
  });

  const loadFirmographics = async () => {
    const f = await getStandardDoc("firmographics", siteId);
    // Get the demographics for the industries
    if (f.employeeSize && f.revenueRange) {
      setTargetProps({
        employeeSize: f.employeeSize,
        revenueRange: f.revenueRange,
        industries: f.industries || [],
        excludeIndustries: f.excludeIndustries || [],
      });
    }

    setPageLoaded(true);
  };

  useEffect(() => {
    if (!pageLoaded) {
      return loadFirmographics();
    }
  }, []);

  const handeSelect = (val, e) => {
    setUpdated(true);
    setTargetProps({
      ...targetProps,
      [e.name]: val,
    });
  };

  function valuetext(value) {
    return `$${value}M`;
  }

  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    setLoading(true);
    await setStandardDoc("firmographics", siteId, {
      ...targetProps,
      siteId,
    });
    setUpdated(false);
    setLoading(false);
  };

  return (
    <Card>
      <CardHeader
        title="Firmographics"
        avatar={<BusinessRounded />}
        action={<KeyboardArrowDownRounded />}
      />
      <CardContent>
        <Stack spacing={3}>
          <div>
            <Typography>
              <b>Target Industries: </b>Please select the industries you wish to
              target below:
            </Typography>
            <Select
              name="industries"
              value={targetProps.industries}
              options={industryOptions}
              {...selectFieldProps}
              isMulti
              onChange={handleIndustryChange}
              placeholder={"Select Industries ..."}
            />
            <br />
            <Typography>
              <b>Exclude Industries: </b>Please select the industries you wish
              to exclude below:
            </Typography>
            <Select
              name="industries"
              value={targetProps.excludeIndustries}
              options={industryOptions}
              {...selectFieldProps}
              isMulti
              onChange={handleIndustryExcludeChange}
              placeholder={"Select Industries ..."}
            />
            <br />
            <Typography>
              <b>Company Size: </b>Please select the number of employees below.
            </Typography>
            <Select
              isMulti
              name="employeeSize"
              value={targetProps.employeeSize}
              options={companySizes}
              {...selectFieldProps}
              onChange={handeSelect}
            />
          </div>

          <div>
            <Typography>
              <b>Revenue: </b>Please select the states you wish to target.
            </Typography>
            Between:{" "}
            <b>
              {targetProps.revenueRange[0] <= 1
                ? `Under $1M`
                : `$${targetProps.revenueRange[0]}M`}
            </b>{" "}
            and{" "}
            <b>
              {targetProps.revenueRange[1] >= 1000
                ? `Over $1B`
                : `$${targetProps.revenueRange[1]}M`}
            </b>{" "}
            annual revenue.
            <Slider
              getAriaLabel={() => "Revenue Range"}
              value={targetProps.revenueRange}
              onChange={(e, v) => {
                setTargetProps({ ...targetProps, revenueRange: v });
                setUpdated(true);
              }}
              min={1}
              max={1000}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
              valueLabelFormat={valuetext}
            />
          </div>
        </Stack>
      </CardContent>
      {updated && !loading && (
        <CardActions>
          <Button variant="contained" startIcon={<Save />} onClick={handleSave}>
            Save Changes
          </Button>
        </CardActions>
      )}
    </Card>
  );
}
