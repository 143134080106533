import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  AddBusinessRounded,
  AdsClickRounded,
  BarChartRounded,
  EditRounded,
  FilePresentRounded,
  MedicationRounded,
  SettingsRounded,
  SpeedRounded,
} from "@mui/icons-material";
import SiteRoutes from "./routes/SiteRoutes";
import SiteHeader from "./site_components/SiteHeader";

export default function SiteDetail() {
  const { siteId } = useParams();

  return (
    <>
      <Stack spacing={2}>
        <SiteHeader siteId={siteId} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <List>
              <Link to={`/site/${siteId}/health`}>
                <ListItem>
                  <ListItemIcon>
                    <MedicationRounded />
                  </ListItemIcon>
                  <ListItemText>Health Center</ListItemText>
                </ListItem>
              </Link>
              <Link to={`/site/${siteId}/company`}>
                <ListItem>
                  <ListItemIcon>
                    <AddBusinessRounded />
                  </ListItemIcon>
                  <ListItemText>Company Info</ListItemText>
                </ListItem>
              </Link>

              <Link to={`/site/${siteId}/library`}>
                <ListItem>
                  <ListItemIcon>
                    <FilePresentRounded />
                  </ListItemIcon>
                  <ListItemText>Media Library</ListItemText>
                </ListItem>
              </Link>

              <Link to={`/site/${siteId}/targeting`}>
                <ListItem>
                  <ListItemIcon>
                    <AdsClickRounded />
                  </ListItemIcon>
                  <ListItemText>Targeting</ListItemText>
                </ListItem>
              </Link>
              <Link to={`/site/${siteId}/create`}>
                <ListItem>
                  <ListItemIcon>
                    <EditRounded />
                  </ListItemIcon>
                  <ListItemText>Content Creation</ListItemText>
                </ListItem>
              </Link>
              <Link to={`/site/${siteId}/settings`}>
                <ListItem>
                  <ListItemIcon>
                    <SettingsRounded />
                  </ListItemIcon>
                  <ListItemText>Settings</ListItemText>
                </ListItem>
              </Link>
              {/* <ListItem>
                <ListItemIcon>
                  <BarChartRounded />
                </ListItemIcon>
                <ListItemText>Reports</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <SettingsRounded />
                </ListItemIcon>
                <ListItemText>Settings</ListItemText>
              </ListItem> */}
            </List>
          </Grid>
          <Grid item={12} md={10} sx={{ paddingRight: 6 }}>
            <SiteRoutes />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
