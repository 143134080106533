import {
  Avatar,
  Card,
  CardHeader,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import icon from "../../../../img/pc_example.png";
import { useDb } from "../../../../contexts/DatabaseContext";

export default function SiteHeader({ siteId }) {
  const { getStandardDoc } = useDb();
  const [site, setSite] = useState();

  const loadSite = async () => {
    const s = await getStandardDoc("sites", siteId);
    setSite(s);
  };

  useEffect(() => {
    return loadSite();
  }, [siteId]);

  if (!site) {
    return <LinearProgress />;
  }

  return (
    <div>
      <Card sx={{ border: "none", boxShadow: "none", background: "none" }}>
        <CardHeader
          title={<Typography variant="h6">{site.name}</Typography>}
          subheader={
            <Typography color="primary">
              <a href={site.url} target="_blank" rel="noopener noreferrer">
                {site.url}
              </a>
            </Typography>
          }
          avatar={<Avatar>{site.name[0]}</Avatar>}
        />
      </Card>
    </div>
  );
}
