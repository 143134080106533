import { LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDb } from "../../../../contexts/DatabaseContext";
import BackLink from "../../../shared/BackLink";
import NoMoreReviews from "./NoMoreReviews";
import ReviewContent from "./ReviewContent";

export default function ReviewQueue() {
  const { modelId } = useParams();
  const { getFirstDocOfQueryAdmin } = useDb();
  const [loaded, setLoaded] = useState(false);
  const [link, setLink] = useState(null);
  const [noReviews, setNoReviews] = useState(false);
  const [requestTime, setRequestTime] = useState(new Date());

  const getUnreviewedLink = async () => {
    const l = await getFirstDocOfQueryAdmin("links", "reviewed", false);
    if (l) {
      setLink(l);
    } else setNoReviews(true);
    setLoaded(true);
  };

  useEffect(() => {
    const unsubscribe = getUnreviewedLink();
    return unsubscribe;
  }, [requestTime]);

  const updateReviewTime = () => {
    setLink(null);
    setLoaded(false);
    setRequestTime(new Date());
  };

  return !loaded ? (
    <LinearProgress />
  ) : (
    <div>
      <BackLink />
      <h1>Review Queue</h1>
      {link && (
        <ReviewContent
          updateReviewTime={updateReviewTime}
          link={link}
          modelId={modelId}
        />
      )}
      {noReviews && <NoMoreReviews />}
    </div>
  );
}
