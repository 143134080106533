export const firmographicQuestions = [
  {
    label: "Company Size",
    description:
      "How many employees does the targeted company have? From minimum to maximum.",
    name: "employeeCount",
    type: "slider",
    props: {
      type: "slider",
      defaultValue: [10, 500],
      rangeSlider: true,
      min: 1,
      max: 1000,
    },
  },
  {
    label: "Industry / Industries",
    description:
      "What industry or industries does the company operate in? Separate each industry with a comma.",
    name: "industries",
    type: "text",
    props: {
      type: "text",
      label: "Industry / Industries",
    },
  },
  {
    label: "Tools and Software",
    description:
      "What tools and software does the company use? Separate each tool or software with a comma.",
    name: "tools",
    type: "text",
    props: {
      type: "text",
      label: "Tools and Software",
    },
  },
  {
    label: "Competitors",
    description:
      "What are the company's competitors? Separate each competitor with a comma.",
    name: "competitors",
    type: "text",
    props: {
      type: "text",
      label: "Competitors",
    },
  },
  {
    label: "Competitor URLS",
    description:
      "What are the company's competitors' URLs? Separate each competitor with a comma.",
    name: "competitorUrls",
    type: "text",
    props: {
      type: "text",
      label: "Competitor URLS",
    },
  },
  {
    label: "Differentiators",
    description:
      "What are the company's differentiators? Separate each differentiator with a comma.",
    name: "differentiators",
    type: "text",
    props: {
      type: "text",
      label: "Differentiators",
    },
  },
];
