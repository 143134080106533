import { Typography } from "@mui/material";
import { Alert } from "@mui/material";
import React from "react";

export default function JsonLPreview({ selections, textInput }) {
  const newLineText = textInput.replace(/\n/g, "\\n");
  const maxLength = 150;

  return (
    <div>
      <Typography>JsonL Preview (Single Item):</Typography>
      <Alert severity="info">
        Please note, we are not showing your full text here in the `Text` part
        of the prompt, as this is just a preview. The full text will be sent to
        the model. Our focus here is on the `completion` part of the prompt.
      </Alert>
      <div className="jsonl-preview">
        <code>
          {`
            {"prompt":" Classify The Following Text into one of the categories, ${
              selections && selections.map((sel) => sel.name).join(", ")
            }}:

            Text: ${newLineText.substring(0, maxLength)} ... 
            \\n\\n###\\n\\n
            ", "completion":"{${
              selections &&
              selections.map((sel) => `"${sel.name}": "${sel.value}"`)
            }}
        `}
        </code>
      </div>
    </div>
  );
}
