import React from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import RMFDataGrid from "../../shared/data-grid/RMFDataGrid";

export default function TrainingData() {
  const { GetCollectionFilter } = useDb();
  const links = GetCollectionFilter("links", "reviewed", true);

  const rows =
    links &&
    links.map((link) => ({
      id: link.id,
      prompt: ` ${link.bodyText.trim().substring(0, 7000)}.  ->`,
      completion: ` {${link.resultJson.map(r => `"${r.name}": "${r.value}"`).join(", ")}} /n`,
    }));

  const columns = [
    { field: "prompt", headerName: "prompt", width: 250 },
    { field: "completion", headerName: "completion", width: 250 },
  ];

  return (
    <div>
      <h1>Training Data</h1>
      <RMFDataGrid data={rows} columns={columns} />
    </div>
  );
}
