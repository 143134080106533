import { AutoMode } from "@mui/icons-material";
import { Avatar, Button, Card, CardActions, CardHeader } from "@mui/material";
import React, { useState } from "react";
import { promptSystemTemplate, promptUserTemplate } from "./promptTemplate";
import PromptDialog from "./PromptDialog";

export default function PromptManager({
  generation,
  wordCount,
  defaultDemographic,
  revenueRanges,
  employeeSizes,
  includeChallenges,
  includeIndustries,
  includeJobs,
  excludeIndustries,
  excludeJobs,
  prompts,
  handlePromptChange,
}) {
  const { topic } = generation;
  const [open, setOpen] = useState(false);

  const [target, setTarget] = useState("system");

  const handleOpen = (t) => {
    setTarget(t);
    setOpen(true);
  };

  return (
    <>
      <Card>
        <CardHeader
          title="Prompts"
          avatar={
            <Avatar>
              <AutoMode />
            </Avatar>
          }
        />
        <CardActions>
          <Button onClick={() => handleOpen("system")} variant="outlined">
            System Prompt
          </Button>
          <Button onClick={() => handleOpen("user")} variant="outlined">
            User Prompt
          </Button>
        </CardActions>
      </Card>

      <PromptDialog
        defaultDemographic={defaultDemographic}
        generation={generation}
        wordCount={wordCount}
        topic={topic}
        open={open}
        close={() => setOpen(false)}
        prompt={prompts[target]}
        handlePromptChange={(newPrompt) =>
          handlePromptChange(target, newPrompt)
        }
        target={target}
        revenueRanges={revenueRanges}
        employeeSizes={employeeSizes}
        includeChallenges={includeChallenges}
        includeIndustries={includeIndustries}
        includeJobs={includeJobs}
        excludeIndustries={excludeIndustries}
        excludeJobs={excludeJobs}
      />
    </>
  );
}
