export const selectFieldProps = {
  menuPortalTarget: document.body,
  styles: { menuPortal: (base) => ({ ...base, zIndex: 9999 }) },
};

export const buyerStageOptions = [
  { value: "Learn", label: "Learn" },
  { value: "Solve", label: "Solve" },
  { value: "Compare", label: "Compare" },
  { value: "Purchase", label: "Purchase" },
  { value: "Loyalty", label: "Loyalty" },
];

export const buyerPersonaOptions = [
  { value: "influencers", label: "Influencer" },
  { value: "endUsers", label: "End User" },
  { value: "decisionMakers", label: "Decision Maker" },
  { value: "financialAuthorities", label: "Financial Authority" },
  { value: "blockers", label: "Blocker" },
];

export const contentFormatOptions = [
  { value: "Single Email", label: "Single Email" },
  { value: "Email Sequence", label: "Email Sequence" },
  { value: "Blog Post", label: "Blog Post" },
  { value: "Social Media Post", label: "Social Media Post" },
  { value: "Article", label: "Article" },
];

export const toneOptions = [
  { value: "Informative", label: "Informative" },
  { value: "Educational", label: "Educational" },
  { value: "Sales Pitch", label: "Sales Pitch" },
  { value: "Academic", label: "Academic" },
];

export const textFieldProps = {
  variant: "outlined",
  fullWidth: true,
  style: { marginBottom: 10, marginTop: 10 },
  size: "small",
};

export const baseModels = [
  { label: "davinci", value: "davinci" },
  { label: "curie", value: "curie" },
  { label: "babbage", value: "babbage" },
  { label: "ada", value: "ada" },
];

export const roles = [
  { label: "Owner", value: "owner" },
  { label: "Editor", value: "editor" },
  { label: "Data Analyst", value: "analyst" },
];

export const countryCodes = [
  { label: "United States of America (the)", value: "USA" },
  { label: "Afghanistan", value: "AFG" },
  { label: "Albania", value: "ALB" },
  { label: "Antarctica", value: "ATA" },
  { label: "Algeria", value: "DZA" },
  { label: "American Samoa", value: "ASM" },
  { label: "Andorra", value: "AND" },
  { label: "Angola", value: "AGO" },
  { label: "Antigua and Barbuda", value: "ATG" },
  { label: "Azerbaijan", value: "AZE" },
  { label: "Argentina", value: "ARG" },
  { label: "Australia", value: "AUS" },
  { label: "Austria", value: "AUT" },
  { label: "Bahamas (the)", value: "BHS" },
  { label: "Bahrain", value: "BHR" },
  { label: "Bangladesh", value: "BGD" },
  { label: "Armenia", value: "ARM" },
  { label: "Barbados", value: "BRB" },
  { label: "Belgium", value: "BEL" },
  { label: "Bermuda", value: "BMU" },
  { label: "Bhutan", value: "BTN" },
  { label: "Bolivia (Plurinational State of)", value: "BOL" },
  { label: "Bosnia and Herzegovina", value: "BIH" },
  { label: "Botswana", value: "BWA" },
  { label: "Bouvet Island", value: "BVT" },
  { label: "Brazil", value: "BRA" },
  { label: "Belize", value: "BLZ" },
  { label: "British Indian Ocean Territory (the)", value: "IOT" },
  { label: "Solomon Islands", value: "SLB" },
  { label: "Virgin Islands (British)", value: "VGB" },
  { label: "Brunei Darussalam", value: "BRN" },
  { label: "Bulgaria", value: "BGR" },
  { label: "Myanmar", value: "MMR" },
  { label: "Burundi", value: "BDI" },
  { label: "Belarus", value: "BLR" },
  { label: "Cambodia", value: "KHM" },
  { label: "Cameroon", value: "CMR" },
  { label: "Canada", value: "CAN" },
  { label: "Cabo Verde", value: "CPV" },
  { label: "Cayman Islands (the)", value: "CYM" },
  { label: "Central African Republic (the)", value: "CAF" },
  { label: "Sri Lanka", value: "LKA" },
  { label: "Chad", value: "TCD" },
  { label: "Chile", value: "CHL" },
  { label: "China", value: "CHN" },
  { label: "Taiwan (Province of China)", value: "TWN" },
  { label: "Christmas Island", value: "CXR" },
  { label: "Cocos (Keeling) Islands (the)", value: "CCK" },
  { label: "Colombia", value: "COL" },
  { label: "Comoros (the)", value: "COM" },
  { label: "Mayotte", value: "MYT" },
  { label: "Congo (the)", value: "COG" },
  { label: "Congo (the Democratic Republic of the)", value: "COD" },
  { label: "Cook Islands (the)", value: "COK" },
  { label: "Costa Rica", value: "CRI" },
  { label: "Croatia", value: "HRV" },
  { label: "Cuba", value: "CUB" },
  { label: "Cyprus", value: "CYP" },
  { label: "Czechia", value: "CZE" },
  { label: "Benin", value: "BEN" },
  { label: "Denmark", value: "DNK" },
  { label: "Dominica", value: "DMA" },
  { label: "Dominican Republic (the)", value: "DOM" },
  { label: "Ecuador", value: "ECU" },
  { label: "El Salvador", value: "SLV" },
  { label: "Equatorial Guinea", value: "GNQ" },
  { label: "Ethiopia", value: "ETH" },
  { label: "Eritrea", value: "ERI" },
  { label: "Estonia", value: "EST" },
  { label: "Faroe Islands (the)", value: "FRO" },
  { label: "Falkland Islands (the) [Malvinas]", value: "FLK" },
  { label: "South Georgia and the South Sandwich Islands", value: "SGS" },
  { label: "Fiji", value: "FJI" },
  { label: "Finland", value: "FIN" },
  { label: "Åland Islands", value: "ALA" },
  { label: "France", value: "FRA" },
  { label: "French Guiana", value: "GUF" },
  { label: "French Polynesia", value: "PYF" },
  { label: "French Southern Territories (the)", value: "ATF" },
  { label: "Djibouti", value: "DJI" },
  { label: "Gabon", value: "GAB" },
  { label: "Georgia", value: "GEO" },
  { label: "Gambia (the)", value: "GMB" },
  { label: "Palestine, State of", value: "PSE" },
  { label: "Germany", value: "DEU" },
  { label: "Ghana", value: "GHA" },
  { label: "Gibraltar", value: "GIB" },
  { label: "Kiribati", value: "KIR" },
  { label: "Greece", value: "GRC" },
  { label: "Greenland", value: "GRL" },
  { label: "Grenada", value: "GRD" },
  { label: "Guadeloupe", value: "GLP" },
  { label: "Guam", value: "GUM" },
  { label: "Guatemala", value: "GTM" },
  { label: "Guinea", value: "GIN" },
  { label: "Guyana", value: "GUY" },
  { label: "Haiti", value: "HTI" },
  { label: "Heard Island and McDonald Islands", value: "HMD" },
  { label: "Holy See (the)", value: "VAT" },
  { label: "Honduras", value: "HND" },
  { label: "Hong Kong", value: "HKG" },
  { label: "Hungary", value: "HUN" },
  { label: "Iceland", value: "ISL" },
  { label: "India", value: "IND" },
  { label: "Indonesia", value: "IDN" },
  { label: "Iran (Islamic Republic of)", value: "IRN" },
  { label: "Iraq", value: "IRQ" },
  { label: "Ireland", value: "IRL" },
  { label: "Israel", value: "ISR" },
  { label: "Italy", value: "ITA" },
  { label: "Côte d'Ivoire", value: "CIV" },
  { label: "Jamaica", value: "JAM" },
  { label: "Japan", value: "JPN" },
  { label: "Kazakhstan", value: "KAZ" },
  { label: "Jordan", value: "JOR" },
  { label: "Kenya", value: "KEN" },
  { label: "Korea (the Democratic People's Republic of)", value: "PRK" },
  { label: "Korea (the Republic of)", value: "KOR" },
  { label: "Kuwait", value: "KWT" },
  { label: "Kyrgyzstan", value: "KGZ" },
  { label: "Lao People's Democratic Republic (the)", value: "LAO" },
  { label: "Lebanon", value: "LBN" },
  { label: "Lesotho", value: "LSO" },
  { label: "Latvia", value: "LVA" },
  { label: "Liberia", value: "LBR" },
  { label: "Libya", value: "LBY" },
  { label: "Liechtenstein", value: "LIE" },
  { label: "Lithuania", value: "LTU" },
  { label: "Luxembourg", value: "LUX" },
  { label: "Macao", value: "MAC" },
  { label: "Madagascar", value: "MDG" },
  { label: "Malawi", value: "MWI" },
  { label: "Malaysia", value: "MYS" },
  { label: "Maldives", value: "MDV" },
  { label: "Mali", value: "MLI" },
  { label: "Malta", value: "MLT" },
  { label: "Martinique", value: "MTQ" },
  { label: "Mauritania", value: "MRT" },
  { label: "Mauritius", value: "MUS" },
  { label: "Mexico", value: "MEX" },
  { label: "Monaco", value: "MCO" },
  { label: "Mongolia", value: "MNG" },
  { label: "Moldova (the Republic of)", value: "MDA" },
  { label: "Montenegro", value: "MNE" },
  { label: "Montserrat", value: "MSR" },
  { label: "Morocco", value: "MAR" },
  { label: "Mozambique", value: "MOZ" },
  { label: "Oman", value: "OMN" },
  { label: "Namibia", value: "NAM" },
  { label: "Nauru", value: "NRU" },
  { label: "Nepal", value: "NPL" },
  { label: "Netherlands (the)", value: "NLD" },
  { label: "Curaçao", value: "CUW" },
  { label: "Aruba", value: "ABW" },
  { label: "Sint Maarten (Dutch part)", value: "SXM" },
  { label: "Bonaire, Sint Eustatius and Saba", value: "BES" },
  { label: "New Caledonia", value: "NCL" },
  { label: "Vanuatu", value: "VUT" },
  { label: "New Zealand", value: "NZL" },
  { label: "Nicaragua", value: "NIC" },
  { label: "Niger (the)", value: "NER" },
  { label: "Nigeria", value: "NGA" },
  { label: "Niue", value: "NIU" },
  { label: "Norfolk Island", value: "NFK" },
  { label: "Norway", value: "NOR" },
  { label: "Northern Mariana Islands (the)", value: "MNP" },
  { label: "United States Minor Outlying Islands (the)", value: "UMI" },
  { label: "Micronesia (Federated States of)", value: "FSM" },
  { label: "Marshall Islands (the)", value: "MHL" },
  { label: "Palau", value: "PLW" },
  { label: "Pakistan", value: "PAK" },
  { label: "Panama", value: "PAN" },
  { label: "Papua New Guinea", value: "PNG" },
  { label: "Paraguay", value: "PRY" },
  { label: "Peru", value: "PER" },
  { label: "Philippines (the)", value: "PHL" },
  { label: "Pitcairn", value: "PCN" },
  { label: "Poland", value: "POL" },
  { label: "Portugal", value: "PRT" },
  { label: "Guinea-Bissau", value: "GNB" },
  { label: "Timor-Leste", value: "TLS" },
  { label: "Puerto Rico", value: "PRI" },
  { label: "Qatar", value: "QAT" },
  { label: "Réunion", value: "REU" },
  { label: "Romania", value: "ROU" },
  { label: "Russian Federation (the)", value: "RUS" },
  { label: "Rwanda", value: "RWA" },
  { label: "Saint Barthélemy", value: "BLM" },
  { label: "Saint Helena, Ascension and Tristan da Cunha", value: "SHN" },
  { label: "Saint Kitts and Nevis", value: "KNA" },
  { label: "Anguilla", value: "AIA" },
  { label: "Saint Lucia", value: "LCA" },
  { label: "Saint Martin (French part)", value: "MAF" },
  { label: "Saint Pierre and Miquelon", value: "SPM" },
  { label: "Saint Vincent and the Grenadines", value: "VCT" },
  { label: "San Marino", value: "SMR" },
  { label: "Sao Tome and Principe", value: "STP" },
  { label: "Saudi Arabia", value: "SAU" },
  { label: "Senegal", value: "SEN" },
  { label: "Serbia", value: "SRB" },
  { label: "Seychelles", value: "SYC" },
  { label: "Sierra Leone", value: "SLE" },
  { label: "Singapore", value: "SGP" },
  { label: "Slovakia", value: "SVK" },
  { label: "Viet Nam", value: "VNM" },
  { label: "Slovenia", value: "SVN" },
  { label: "Somalia", value: "SOM" },
  { label: "Spain", value: "ESP" },
  { label: "South Africa", value: "ZAF" },
  { label: "South Sudan", value: "SSD" },
  { label: "Sudan (the)", value: "SDN" },
  { label: "Western Sahara", value: "ESH" },
  { label: "Suriname", value: "SUR" },
  { label: "Svalbard and Jan Mayen", value: "SJM" },
  { label: "Eswatini", value: "SWZ" },
  { label: "Sweden", value: "SWE" },
  { label: "Switzerland", value: "CHE" },
  { label: "Syrian Arab Republic", value: "SYR" },
  { label: "Tajikistan", value: "TJK" },
  { label: "Thailand", value: "THA" },
  { label: "Togo", value: "TGO" },
  { label: "Tokelau", value: "TKL" },
  { label: "Tonga", value: "TON" },
  { label: "Trinidad and Tobago", value: "TTO" },
  { label: "United Arab Emirates (the)", value: "ARE" },
  { label: "Tunisia", value: "TUN" },
  { label: "Turkey", value: "TUR" },
  { label: "Turkmenistan", value: "TKM" },
  { label: "Turks and Caicos Islands (the)", value: "TCA" },
  { label: "Tuvalu", value: "TUV" },
  { label: "Uganda", value: "UGA" },
  { label: "Ukraine", value: "UKR" },
  { label: "Republic of North Macedonia", value: "MKD" },
  { label: "Egypt", value: "EGY" },
  {
    label: "United Kingdom of Great Britain and Northern Ireland (the)",
    value: "GBR",
  },
  { label: "Guernsey", value: "GGY" },
  { label: "Jersey", value: "JEY" },
  { label: "Isle of Man", value: "IMN" },
  { label: "Tanzania, United Republic of", value: "TZA" },
  { label: "United States of America (the)", value: "USA" },
  { label: "Virgin Islands (U.S.)", value: "VIR" },
  { label: "Burkina Faso", value: "BFA" },
  { label: "Uruguay", value: "URY" },
  { label: "Uzbekistan", value: "UZB" },
  { label: "Venezuela (Bolivarian Republic of)", value: "VEN" },
  { label: "Wallis and Futuna", value: "WLF" },
  { label: "Samoa", value: "WSM" },
  { label: "Yemen", value: "YEM" },
  { label: "Zambia", value: "ZMB" },
  { label: "Zimbabwe", value: "ZWE" },
];

export const industriesTemp = [
  {
    name: "Accounting ",
  },
  {
    name: "Airlines/Aviation",
  },
  {
    name: "Alternative Dispute Resolution",
  },
  {
    name: "Alternative Medicine",
  },
  {
    name: "Animation",
  },
  {
    name: "Apparel/Fashion",
  },
  {
    name: "Architecture/Planning",
  },
  {
    name: "Arts/Crafts",
  },
  {
    name: "Automotive",
  },
  {
    name: "Aviation/Aerospace",
  },
  {
    name: "Banking/Mortgage",
  },
  {
    name: "Biotechnology/Greentech",
  },
  {
    name: "Broadcast Media",
  },
  {
    name: "Building Materials",
  },
  {
    name: "Business Supplies/Equipment",
  },
  {
    name: "Capital Markets/Hedge Fund/Private Equity",
  },
  {
    name: "Chemicals",
  },
  {
    name: "Civic/Social Organization",
  },
  {
    name: "Civil Engineering",
  },
  {
    name: "Commercial Real Estate",
  },
  {
    name: "Computer Games",
  },
  {
    name: "Computer Hardware",
  },
  {
    name: "Computer Networking",
  },
  {
    name: "Computer Software/Engineering",
  },
  {
    name: "Computer/Network Security",
  },
  {
    name: "Construction",
  },
  {
    name: "Consumer Electronics",
  },
  {
    name: "Consumer Goods",
  },
  {
    name: "Consumer Services",
  },
  {
    name: "Cosmetics",
  },
  {
    name: "Dairy",
  },
  {
    name: "Defense/Space",
  },
  {
    name: "Design",
  },
  {
    name: "E-Learning",
  },
  {
    name: "Education Management",
  },
  {
    name: "Electrical/Electronic Manufacturing",
  },
  {
    name: "Entertainment/Movie Production",
  },
  {
    name: "Environmental Services",
  },
  {
    name: "Events Services",
  },
  {
    name: "Executive Office",
  },
  {
    name: "Facilities Services",
  },
  {
    name: "Farming",
  },
  {
    name: "Financial Services",
  },
  {
    name: "Fine Art",
  },
  {
    name: "Fishery",
  },
  {
    name: "Food Production",
  },
  {
    name: "Food/Beverages",
  },
  {
    name: "Fundraising",
  },
  {
    name: "Furniture",
  },
  {
    name: "Gambling/Casinos",
  },
  {
    name: "Glass/Ceramics/Concrete",
  },
  {
    name: "Government Administration",
  },
  {
    name: "Government Relations",
  },
  {
    name: "Graphic Design/Web Design",
  },
  {
    name: "Health/Fitness",
  },
  {
    name: "Higher Education/Acadamia",
  },
  {
    name: "Hospital/Health Care",
  },
  {
    name: "Hospitality",
  },
  {
    name: "Human Resources/HR",
  },
  {
    name: "Import/Export",
  },
  {
    name: "Individual/Family Services",
  },
  {
    name: "Industrial Automation",
  },
  {
    name: "Information Services",
  },
  {
    name: "Information Technology/IT",
  },
  {
    name: "Insurance",
  },
  {
    name: "International Affairs",
  },
  {
    name: "International Trade/Development",
  },
  {
    name: "Internet",
  },
  {
    name: "Investment Banking/Venture",
  },
  {
    name: "Investment Management/Hedge Fund/Private Equity",
  },
  {
    name: "Judiciary",
  },
  {
    name: "Law Enforcement",
  },
  {
    name: "Law Practice/Law Firms",
  },
  {
    name: "Legal Services",
  },
  {
    name: "Legislative Office",
  },
  {
    name: "Leisure/Travel",
  },
  {
    name: "Library",
  },
  {
    name: "Logistics/Procurement",
  },
  {
    name: "Luxury Goods/Jewelry",
  },
  {
    name: "Machinery",
  },
  {
    name: "Management Consulting",
  },
  {
    name: "Maritime",
  },
  {
    name: "Market Research",
  },
  {
    name: "Marketing/Advertising/Sales",
  },
  {
    name: "Mechanical or Industrial Engineering",
  },
  {
    name: "Media Production",
  },
  {
    name: "Medical Equipment",
  },
  {
    name: "Medical Practice",
  },
  {
    name: "Mental Health Care",
  },
  {
    name: "Military Industry",
  },
  {
    name: "Mining/Metals",
  },
  {
    name: "Motion Pictures/Film",
  },
  {
    name: "Museums/Institutions",
  },
  {
    name: "Music",
  },
  {
    name: "Nanotechnology",
  },
  {
    name: "Newspapers/Journalism",
  },
  {
    name: "Non-Profit/Volunteering",
  },
  {
    name: "Oil/Energy/Solar/Greentech",
  },
  {
    name: "Online Publishing",
  },
  {
    name: "Other Industry",
  },
  {
    name: "Outsourcing/Offshoring",
  },
  {
    name: "Package/Freight Delivery",
  },
  {
    name: "Packaging/Containers",
  },
  {
    name: "Paper/Forest Products",
  },
  {
    name: "Performing Arts",
  },
  {
    name: "Pharmaceuticals",
  },
  {
    name: "Philanthropy",
  },
  {
    name: "Photography",
  },
  {
    name: "Plastics",
  },
  {
    name: "Political Organization",
  },
  {
    name: "Primary/Secondary Education",
  },
  {
    name: "Printing",
  },
  {
    name: "Professional Training",
  },
  {
    name: "Program Development",
  },
  {
    name: "Public Relations/PR",
  },
  {
    name: "Public Safety",
  },
  {
    name: "Publishing Industry",
  },
  {
    name: "Railroad Manufacture",
  },
  {
    name: "Ranching",
  },
  {
    name: "Real Estate/Mortgage",
  },
  {
    name: "Recreational Facilities/Services",
  },
  {
    name: "Religious Institutions",
  },
  {
    name: "Renewables/Environment",
  },
  {
    name: "Research Industry",
  },
  {
    name: "Restaurants",
  },
  {
    name: "Retail Industry",
  },
  {
    name: "Security/Investigations",
  },
  {
    name: "Semiconductors",
  },
  {
    name: "Shipbuilding",
  },
  {
    name: "Sporting Goods",
  },
  {
    name: "Sports",
  },
  {
    name: "Staffing/Recruiting",
  },
  {
    name: "Supermarkets",
  },
  {
    name: "Telecommunications",
  },
  {
    name: "Textiles",
  },
  {
    name: "Think Tanks",
  },
  {
    name: "Tobacco",
  },
  {
    name: "Translation/Localization",
  },
  {
    name: "Transportation",
  },
  {
    name: "Utilities",
  },
  {
    name: "Venture Capital/VC",
  },
  {
    name: "Veterinary",
  },
  {
    name: "Warehousing",
  },
  {
    name: "Wholesale",
  },
  {
    name: "Wine/Spirits",
  },
  {
    name: "Wireless",
  },
  {
    name: "Writing/Editing",
  },
];

export const companySizes = [
  { value: "0-10", label: "0-10" },
  { value: "11-20", label: "11-20" },
  { value: "21-50", label: "21-50" },
  { value: "51-100", label: "51-100" },
  { value: "101-200", label: "101-200" },
  { value: "201-500", label: "201-500" },
  { value: "501-1000", label: "501-1000" },
  { value: "1001-2000", label: "1001-2000" },
  { value: "2001-5000", label: "2001-5000" },
  { value: "5001-10000", label: "5001-10000" },
  { value: "10001+", label: "10001+" },
];

export const statesOptions = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Alberta", value: "AB" },
  { label: "American Samoa", value: "AS" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "Armed Forces (AE)", value: "AE" },
  { label: "Armed Forces Americas", value: "AA" },
  { label: "Armed Forces Pacific", value: "AP" },
  { label: "British Columbia", value: "BC" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "District Of Columbia", value: "DC" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Guam", value: "GU" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Manitoba", value: "MB" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Brunswick", value: "NB" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "Newfoundland", value: "NF" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Northwest Territories", value: "NT" },
  { label: "Nova Scotia", value: "NS" },
  { label: "Nunavut", value: "NU" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Ontario", value: "ON" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Prince Edward Island", value: "PE" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Quebec", value: "PQ" },
  { label: "Rhode Island", value: "RI" },
  { label: "Saskatchewan", value: "SK" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virgin Islands", value: "VI" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
  { label: "Yukon Territory", value: "YT" },
];
