import { ArrowForward, Home } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
  Button,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function CompleteDialog({ open, close, nextLink, siteId }) {
  return (
    <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
      <DialogTitle>Section Complete!</DialogTitle>
      <DialogContent>
        <Typography>
          You have completed the section. You can continue to the next section
          or return to the dashboard.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Link to={`/site/${siteId}`}>
          <Button color="primary" startIcon={<Home />}>
            Return to Dashboard
          </Button>
        </Link>
        {nextLink && (
          <Link to={`${nextLink}/${siteId}`}>
            <Button
              color="primary"
              variant="contained"
              endIcon={<ArrowForward />}
            >
              Continue
            </Button>
          </Link>
        )}
      </DialogActions>
    </Dialog>
  );
}
