import {
  Button,
  Card,
  CardActions,
  CardContent,
  LinearProgress,
  Typography,
} from "@mui/material";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { useDb } from "../../../../contexts/DatabaseContext";
import BackLink from "../../../shared/BackLink";
import ReviewTable from "./ReviewTable";

export default function ReviewLinks() {
  const { modelId, siteId } = useParams();
  const { GetLinksForSite } = useDb();
  const links = GetLinksForSite(siteId);
  return (
    <div>
      <BackLink />
      <Typography variant="h4">Review Links</Typography>
      <Card>
        <CardActions>
          <Link to={`/site-review-queue/${modelId}/${siteId}`}>
            <Button variant="contained" color="primary">
              Begin Review
            </Button>
          </Link>
        </CardActions>
      </Card>
      <br />
      <Card>
        <CardContent>
          <ReviewTable links={links} />
        </CardContent>
      </Card>
    </div>
  );
}
