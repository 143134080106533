import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  IconButton,
  ButtonGroup,
  Chip,
  Card,
  CardHeader,
  CardContent,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Add, Cancel, CheckCircle, Save } from "@mui/icons-material";
import React, { useState } from "react";
import { FcAddColumn, FcVideoFile } from "react-icons/fc";
import Select from "react-select";
import { useDb } from "../../../../../contexts/DatabaseContext";
import { selectFieldProps, textFieldProps } from "../../../../shared/constants";
import { snakeCaseText } from "../../../../shared/utils/textFormattingUtils";

export default function CreateSelectable({ open, close, model }) {
  const { addStandardDoc } = useDb();
  const [options, setOptions] = useState([]);
  const [adding, setAdding] = useState(false);
  const [addValue, setAddValue] = useState("");
  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allowMulti, setAllowMulti] = useState(false);

  const toggleAdding = () => {
    setAddValue(null);
    setAdding(!adding);
  };

  const toggleAllowMulti = () => {
    setAllowMulti(!allowMulti);
  };

  const handleClose = () => {
    setAddValue(null);
    setOptions([]);
    setLoading(false);
    setAllowMulti(false);
    setName("");
    close();
  };

  const handleChange = (e) => {
    setAddValue(e.target.value);
  };

  const handleName = (e) => {
    setName(snakeCaseText(e.target.value));
  }

  const handleAdd = () => {
    if (options.some((option) => option.value === addValue)) {
      window.alert("Duplicate values in options are not allowed.");
      return;
    }
    setOptions([...options, { value: addValue, label: addValue }]);
    setAddValue(null);
    setAdding(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await addStandardDoc("selections", {
        name,
        options,
        modelId: model.id,
        allowMulti,
      });
      handleClose();
    } catch (err) {
      window.alert(err.message || "Error adding selection.");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add Selection</DialogTitle>
      <DialogContent>
        <TextField
          {...textFieldProps}
          label="Selection Name"
          onChange={handleName}
          value={name}
        />
        <Card>
          <CardHeader
            title="Options"
            subheader="Add options for your selection."
            avatar={<FcAddColumn />}
            action={
              // Allow Multi Checkbox
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allowMulti}
                    onChange={toggleAllowMulti}
                    name="allowMulti"
                    color="primary"
                  />
                }
                label="Multi-Select"
              />
            }
          />
          <CardContent>
            <div>
              {options &&
                options.map((option) => (
                  <Chip
                    key={option.value}
                    label={option.label}
                    onDelete={() =>
                      setOptions(
                        options.filter((o) => o.value !== option.value)
                      )
                    }
                    style={{ margin: "0.5rem" }}
                  />
                ))}
            </div>
            {adding && (
              <div style={{ marginBottom: 10 }}>
                <TextField
                  label="Option"
                  {...textFieldProps}
                  value={addValue}
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 50,
                  }}
                  InputProps={{
                    endAdornment: (
                      <ButtonGroup>
                        <IconButton color="secondary" onClick={toggleAdding} size="large">
                          <Cancel />
                        </IconButton>
                        <IconButton disabled={!addValue} color="primary" onClick={handleAdd} size="large">
                          <CheckCircle />
                        </IconButton>
                      </ButtonGroup>
                    ),
                  }}
                />
                <span
                  style={{
                    float: "right",
                    color: addValue?.length === 50 ? "red" : "inherit",
                  }}
                >
                  {addValue?.length || 0}/50
                </span>
              </div>
            )}
            {!adding && (
              <Button
                onClick={toggleAdding}
                color="primary"
                startIcon={<Add />}
              >
                Add Option
              </Button>
            )}
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardHeader title="Preview" avatar={<FcVideoFile />} />
          <CardContent>
            <Select
              options={options}
              {...selectFieldProps}
              className="rmf-select"
              isMulti={allowMulti}
              placeholder={`Select ${name} ...`}
            />
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          startIcon={<Save />}
          variant="contained"
          onClick={handleSubmit}
          color="primary"
          disabled={loading || !options.length > 0 || !name}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
