import { Check, Error, HourglassEmptyRounded } from "@mui/icons-material";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import React from "react";
import RMFDataGrid from "../../../../../shared/data-grid/RMFDataGrid";
import { AiOutlineLink } from "react-icons/ai";

export default function LinksStatus({ links }) {
  const rows =
    links &&
    links.map((link) => ({
      ...link,
      title: link.title || link.url,
      caption: link.bodyText ? link.bodyText.substring(0, 100) : null,
      ...link.resultJson,
    }));

  const columns = [
    {
      field: "status",
      headerName: "Scraped",
      width: 80,
      renderCell: (params) => {
        return params.row.processed ? (
          <Avatar style={{ background: "#1bc39a", height: 30, width: 30 }}>
            <Check />{" "}
          </Avatar>
        ) : (
          <Avatar style={{ height: 30, width: 30 }}>
            <HourglassEmptyRounded />
          </Avatar>
        );
      },
    },
    {
      field: "analyzed",
      headerName: "Analyzed",
      width: 80,
      renderCell: (params) => {
        return params.row.analyzed ? (
          params.row.error ? (
            <Avatar style={{ background: "#f4511d", height: 30, width: 30 }}>
              <Error />{" "}
            </Avatar>
          ) : (
            <Avatar style={{ background: "#1bc39a", height: 30, width: 30 }}>
              <Check />{" "}
            </Avatar>
          )
        ) : (
          <Avatar style={{ height: 30, width: 30 }}>
            <HourglassEmptyRounded />
          </Avatar>
        );
      },
    },
    { field: "title", headerName: "Page Title", width: 300 },
    {
      field: "url",
      headerName: "URL",
      width: 80,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.url}>
            <IconButton
              href={params.row.url}
              target="_blank"
              rel="noopener noreferrer"
              size="large"
            >
              <AiOutlineLink />
            </IconButton>
          </Tooltip>
        );
      },
    },
    { field: "buyer_persona", headerName: "Primary Persona", width: 150 },
    { field: "buyer_journey", headerName: "Buyer Stage", width: 150 },
    {
      field: "page_summary",
      headerName: "Page Summary",
      width: 150,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.page_summary}>
            {params.row.page_summary}
          </Tooltip>
        );
      },
    },
  ];

  return (
    <div>
      <RMFDataGrid data={rows} columns={columns} />
    </div>
  );
}
