import { Add, Edit } from "@mui/icons-material";
import { Button, Chip, IconButton } from "@mui/material";
import React, { useState } from "react";
import NewFirmographicDialog from "./NewFirmographicDialog";
import { useDb } from "../../../../../contexts/DatabaseContext";
import RMFDataGrid from "../../../../shared/data-grid/RMFDataGrid";

export default function Firmographics({ siteId }) {
  const [addOpen, setAddOpen] = useState(false);
  const { GetOrgCollectionWithFilter } = useDb();
  const firmographics = GetOrgCollectionWithFilter(
    "firmographics",
    "siteId",
    siteId
  );

  const rows =
    firmographics &&
    firmographics.map((info) => ({
      ...info,
      complete: info.firmographicsInfoComplete,
    }));

  const columns = [
    {
      field: "url",
      headerName: "",
      width: 40,
      renderCell: (params) => {
        return (
          <IconButton size="small">
            <Edit />
          </IconButton>
        );
      },
    },
    { field: "name", headerName: "Name", width: 350 },
    {
      field: "status",
      headerName: "Status",
      width: 350,
      renderCell: (params) => {
        return params.row.status ? (
          <Chip label="Complete" color="primary" />
        ) : (
          <Chip label="Draft" />
        );
      },
    },
  ];
  return (
    <div>
      <Button onClick={() => setAddOpen(true)} startIcon={<Add />}>
        Add Firmographics
      </Button>

      {firmographics && <RMFDataGrid data={rows} columns={columns} />}

      <NewFirmographicDialog
        open={addOpen}
        close={() => setAddOpen(false)}
        siteId={siteId}
      />
    </div>
  );
}
