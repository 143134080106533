import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

const config = {
  // Paste your Firebase config here
  apiKey: "AIzaSyAl3O76neMqPtVgdlaVwaJJO2F9N4kWckU",
  authDomain: "sla-categorizer.firebaseapp.com",
  projectId: "sla-categorizer",
  storageBucket: "sla-categorizer.appspot.com",
  messagingSenderId: "681363067985",
  appId: "1:681363067985:web:b438de2006b8c5faa61470",
};

const app = firebase.initializeApp(config);

export const auth = app.auth();
export const db = app.firestore();
export const storage = app.storage();
export const functions = app.functions();

export default app;
