import { NoteAddRounded } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import RMFDataGrid from "../../../../shared/data-grid/RMFDataGrid";
import UploadMediaLibrary from "./UploadMediaLibrary";
import { useDb } from "../../../../../contexts/DatabaseContext";

export default function MediaLibrary() {
  const { siteId } = useParams();
  const { GetCollectionFilter, deleteStandardDoc } = useDb();
  const mediaFiles = GetCollectionFilter("media_library", "siteId", siteId);
  const [addOpen, setAddOpen] = useState(false);

  const handleDelete = async (docId) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this? This action can not be undone."
    );
    if (confirm) {
      await deleteStandardDoc("media_library", docId);
    }
  };

  const rows =
    mediaFiles &&
    mediaFiles.map((mf) => ({
      ...mf,
    }));

  const columns = [
    { field: "title", headerName: "Document Title", width: 400 },
    {
      field: "fileName",
      headerName: "File Name",
      width: 600,
      renderCell: (params) => {
        return (
          <Button
            href={params.row.fileUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {params.row.fileName}
          </Button>
        );
      },
    },
    {
      field: "actions",
      headerName: "Action",
      width: 80,
      renderCell: (params) => {
        return (
          <Button
            size="small"
            variant="outlined"
            color="error"
            onClick={() => handleDelete(params.row.id)}
          >
            Delete
          </Button>
        );
      },
    },
  ];
  return (
    <div>
      <Card className="site-detail-header">
        <CardHeader
          title="Media Library"
          subheader="Upload White Papers, research journals, advertisements or any other media"
        />
        <CardActions>
          <Button
            variant="contained"
            startIcon={<NoteAddRounded />}
            onClick={() => setAddOpen(true)}
          >
            Upload File
          </Button>
        </CardActions>
      </Card>
      <Card>
        <CardContent>
          {rows && <RMFDataGrid data={rows} columns={columns} />}
        </CardContent>
      </Card>

      <UploadMediaLibrary
        open={addOpen}
        close={() => setAddOpen(false)}
        siteId={siteId}
      />
    </div>
  );
}
