import { Info } from "@mui/icons-material";
import { Card, CardHeader, Slider, Tooltip, Typography } from "@mui/material";
import React from "react";

export default function WordCount({ wordCount, setWordCount }) {
  return (
    <div>
      <Card className="company-checklist-item">
        <CardHeader
          title={
            <Typography>
              Word Count: <b>{wordCount}</b>{" "}
            </Typography>
          }
          subheader={
            <Slider
              aria-label="Word Count"
              defaultValue={650}
              valueLabelDisplay="off"
              value={wordCount}
              onChange={(e, value) => setWordCount(value)}
              step={50}
              marks
              min={50}
              max={2500}
            />
          }
          action={
            <Tooltip
              title="The AI will use this word length as a rough
        approximation of the length of the content you want. It may not be
        exact, and the readability of the content will be prioritized over
        getting to a specific word length."
              arrow
            >
              <Info color="primary" />
            </Tooltip>
          }
        />
      </Card>
    </div>
  );
}
