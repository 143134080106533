import { ArrowForward } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDb } from "../../../../contexts/DatabaseContext";
import { textFieldProps } from "../../../shared/constants";

export default function NewInfoTypeDialog({
  open,
  close,
  siteId,
  infoTypeCollection,
  infoTypeLabel,
}) {
  const [typeName, setTypeName] = useState("");
  const [loading, setLoading] = useState(false);
  const { addStandardDoc } = useDb();
  const history = useHistory();

  const handleClose = () => {
    setLoading(false);
    setTypeName("");
    close();
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const documentId = await addStandardDoc(`${infoTypeCollection}`, {
        name: typeName,
        siteId,
        label: typeName,
        value: typeName,
      });
      history.push(`/${infoTypeCollection}-info/${siteId}/${documentId}`);
      handleClose();
    } catch (err) {
      window.alert(err.message);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add {infoTypeLabel}</DialogTitle>
      <DialogContent>
        <TextField
          {...textFieldProps}
          label={`${infoTypeLabel} Name / Title`}
          onChange={(e) => setTypeName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          endIcon={<ArrowForward />}
          disabled={loading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
