import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { FcClapperboard } from "react-icons/fc";
import JsonLPreview from "../utilities/ JsonLPreview";
import Select from "react-select";
import { selectFieldProps, textFieldProps } from "../../../../shared/constants";

export default function TextClassificationPreview({ selections }) {
  const [textInput, setTextInput] = useState("");
  const [selectionsPicked, setSelectionsPicked] = useState([]);

  const handleSelect = (v, e) => {
    setSelectionsPicked((prev) => {
      const newSelections = [...prev];
      const index = newSelections.findIndex((s) => s.name === e.name);
      if (index === -1) {
        newSelections.push({ name: e.name, value: v.value });
      } else {
        newSelections[index].value = v.value;
      }
      return newSelections;
    });
  };

  return (
    <div style={{ marginTop: 25 }}>
      <Card>
        <CardHeader
          title="Preview"
          subheader="Preview your model input here"
          avatar={<FcClapperboard />}
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={0}>
                {selections &&
                  selections.map((sel) => (
                    <Grid key={sel.id} item xs={6}>
                      <div
                        style={{
                            marginRight: 5
                        }}
                      >
                        <Select
                          {...selectFieldProps}
                          options={sel.options}
                          placeholder={`Select ${sel.name} ...`}
                          className="rmf-select"
                          name={sel.name}
                          onChange={handleSelect}
                        />
                      </div>
                    </Grid>
                  ))}
              </Grid>
              <TextField
                {...textFieldProps}
                label="Text to classify"
                multiline
                minRows={10}
                value={textInput}
                onChange={(e) => setTextInput(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <JsonLPreview
                selections={selectionsPicked}
                textInput={textInput}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
