import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { Edit, ViewArray, Visibility } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { FcBiotech, FcOk } from "react-icons/fc";
import { Link, useParams } from "react-router-dom";
import { useDb } from "../../../contexts/DatabaseContext";
import BackLink from "../../shared/BackLink";
import ReviewTable from "../models/review/ReviewTable";
import ModelSettings from "./create_model/utilities/ModelSettings";

export default function ModelDetail() {
  const { modelId } = useParams();
  const { getStandardDoc, GetCollectionFilter } = useDb();
  const [model, setModel] = useState();
  const links = GetCollectionFilter("links", "reviewed", true);

  const retrieveModel = async () => {
    const m = await getStandardDoc("models", modelId);
    setModel(m);
  };

  useEffect(() => {
    const unsubscribe = retrieveModel();
    return unsubscribe;
  }, [modelId]);

  return !model ? (
    <LinearProgress />
  ) : (
    <div>
      <Container>
        <BackLink />
        <h2 className="model-header">{model.name}</h2>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card>
              <CardHeader title="Training Data" avatar={<FcBiotech />} />
              <CardActions>
                <Link to={`/view/${model.modelType}/${model.id}`}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<Visibility />}
                  >
                    Add Data Manually
                  </Button>
                </Link>
                <Link to={`/review-queue/${modelId}`}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<ViewArray />}
                  >
                    Add Review Data
                  </Button>
                </Link>

                <Link to={`/${model.modelType}/${model.id}`}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<Edit />}
                  >
                    Edit Form
                  </Button>
                </Link>
              </CardActions>
              <CardContent>
                <Typography>
                  Reviewed Entries: {links && links.length}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <ModelSettings model={model} />
          </Grid>
        </Grid>
        <br/>
        <Card>
          <CardHeader title="Reviewed Data" avatar={<FcOk />} />
          <CardContent>{links && <ReviewTable links={links} />}</CardContent>
        </Card>
      </Container>
    </div>
  );
}
