import { CardHeader, Container, LinearProgress, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import GenerateContentStep from "../GenerateContentStep";
import { useDb } from "../../../../../contexts/DatabaseContext";
import BackLink from "../../../../shared/BackLink";
import PromptManager from "../prompt-editor/PromptManager.js";
import {
  promptSystemTemplate,
  promptUserTemplate,
} from "../prompt-editor/promptTemplate.js";

export default function ContentCreator() {
  const { siteId, generateId } = useParams();
  const {
    getStandardDoc,
    getSiteDefaultDemographic,
    getSiteDefaultFirmographic,
  } = useDb();
  const [wordCount, setWordCount] = useState(650);
  const [generation, setGeneration] = useState();
  const [site, setSite] = useState();
  const [contentSettings, setContentSettings] = useState();
  const [contextConfig, setContextConfig] = useState();
  const [keywords, setKeywords] = useState([]);

  const [tone, setTone] = useState();
  const [defaultDemographic, setDefaultDemographic] = useState();
  const [defaultFirmographics, setDefaultFirmographics] = useState();

  const [includeJobs, setIncludeJobs] = useState("");
  const [includeChallenges, setIncludeChallenges] = useState("");
  const [excludeJobs, setExcludeJobs] = useState("");

  const [includeIndustries, setIncludeIndustries] = useState("");
  const [excludeIndustries, setExcludeIndustries] = useState("");

  const [employeeSizes, setEmployeeSizes] = useState("");
  const [revenueRanges, setRevenueRanges] = useState("");

  const loadGeneration = async () => {
    const gen = await getStandardDoc("generate", generateId);
    const s = await getStandardDoc("sites", siteId);
    const demo = await getSiteDefaultDemographic(siteId);
    const firmo = await getSiteDefaultFirmographic(siteId);

    setDefaultDemographic(demo);
    setDefaultFirmographics(firmo);

    console.log(firmo);

    let targetedJobTitles = "";

    if (demo.data && demo.data[gen.buyerPersona]?.list) {
      demo.data &&
        demo.data[gen.buyerPersona]?.list.forEach((ind) => {
          targetedJobTitles += `${ind.value}, `;
        });
    }

    setIncludeJobs(targetedJobTitles);

    if (demo.data[gen.buyerPersona]?.challenges) {
      setIncludeChallenges(demo.data[gen.buyerPersona]?.challenges);
    }

    let excludedJobTitles = "";
    if (demo.data && demo.data[gen.buyerPersona]?.excludes) {
      demo.data &&
        demo.data[gen.buyerPersona]?.excludes.forEach((ind) => {
          excludedJobTitles += `${ind.value}, `;
        });
    }
    setExcludeJobs(excludedJobTitles);

    let includedIndustries = "";
    if (firmo.industries && firmo.industries.length > 0) {
      firmo.industries.forEach((ind) => {
        includedIndustries += `${ind.value}, `;
      });
    }
    setIncludeIndustries(includedIndustries);

    let exIndustries = "";
    if (firmo.excludeIndustries && firmo.excludeIndustries.length > 0) {
      firmo.excludeIndustries.forEach((ind) => {
        exIndustries += `${ind.value}, `;
      });
    }
    console.log(exIndustries);
    setExcludeIndustries(exIndustries);

    let employees = "";
    if (firmo.employeeSize && firmo.employeeSize.length > 0) {
      firmo.employeeSize.forEach((ind) => {
        employees += `${ind.value}, `;
      });
    }
    setEmployeeSizes(employees);

    let revenues = "";
    if (firmo.revenueRange && firmo.revenueRange.length > 0) {
      revenues = `$${firmo.revenueRange[0]}M to $${
        firmo.revenueRange[firmo.revenueRange.length - 1]
      }M`;
    }
    setRevenueRanges(revenues);

    if (gen && gen.contentConfig) {
      const objectEntries = Object.entries(gen.contentConfig);
      setContentSettings(objectEntries);
    }
    if (gen && gen.contextConfig) {
      setContextConfig(gen.contextConfig);
      if (gen.contextConfig.wordCount) {
        setWordCount(gen.contextConfig.wordCount);
      }
      if (gen.contextConfig.keywords && gen.contextConfig.keywords.length > 0) {
        setKeywords(gen.contextConfig.keywords);
      }
    }
    setSite(s);
    setGeneration(gen);
  };

  const [prompts, setPrompts] = useState({
    system: promptSystemTemplate,
    user: promptUserTemplate,
  });

  const handlePromptChange = (target, newPrompt) => {
    setPrompts({
      ...prompts,
      [target]: newPrompt,
    });
  };

  useEffect(() => {
    const unsubscribe = loadGeneration();
    return unsubscribe;
  }, [generateId, siteId]);

  return !site || !generation ? (
    <LinearProgress />
  ) : (
    <div>
      <Container>
        <BackLink override={`/site/${siteId}/create`} label="Content Manager" />
        <CardHeader
          title="Generate and Refine Content"
          subheader={generation.topic}
        />
        <Stack spacing={2}>
          <PromptManager
            generation={generation}
            wordCount={wordCount}
            defaultDemographic={defaultDemographic}
            revenueRanges={revenueRanges}
            employeeSizes={employeeSizes}
            includeChallenges={includeChallenges}
            includeIndustries={includeIndustries}
            includeJobs={includeJobs}
            excludeIndustries={excludeIndustries}
            excludeJobs={excludeJobs}
            prompts={prompts}
            handlePromptChange={handlePromptChange}
          />
          <GenerateContentStep
            siteId={siteId}
            wordCount={wordCount}
            generateId={generateId}
            contentSettings={contentSettings}
            setGeneration={setGeneration}
            contextConfig={contextConfig}
            generation={generation}
            site={site}
            tone={tone}
            prompts={prompts}
          />
        </Stack>
      </Container>
    </div>
  );
}
