import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { textFieldProps } from "../../../../shared/constants";
import { useDb } from "../../../../../contexts/DatabaseContext";

export default function AddCompetitor({ open, close, siteId }) {
  const { addStandardDoc } = useDb();

  const [competitor, setCompetitor] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setCompetitor({
      ...competitor,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await addStandardDoc("competitors", {
      ...competitor,
      siteId,
    });
    setLoading(false);
    setCompetitor({});
    close();
  };

  return (
    <Dialog open={open} onClose={() => close()} maxWidth="sm" fullWidth>
      <DialogTitle>Add Competitor</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Stack spacing={1}>
            <br />
            <TextField
              {...textFieldProps}
              label="Competitor Name"
              name="name"
              onChange={handleChange}
              required
            />
            <TextField
              {...textFieldProps}
              label="Website URL"
              name="url"
              type="url"
              onChange={handleChange}
              required
            />
            <Alert severity="info">
              We will attempt to scan the url of this company to ascertain
              information
            </Alert>
            <TextField
              {...textFieldProps}
              label="Key Differentiators (Optional)"
              helperText="What makes this competitor different to your company? You can leave this blank and we'll try to get this information automatically."
              multiline
              name="key_diffs"
              rows={3}
              onChange={handleChange}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} color="error" onClick={() => close()}>
            Cancel
          </Button>
          <Button disabled={loading} type="submit" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
