import { Container, LinearProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDb } from "../../../../contexts/DatabaseContext";
import BackLink from "../../../shared/BackLink";
import InfoWizard from "../targeting/info-wizard/InfoWizard";

const steps = [
  {
    label: "Ideal Age",
    description: "What is your persona's age range?",
    name: "ageRange",
    type: "slider",
    props: {
      type: "slider",
      defaultValue: [34, 56],
      rangeSlider: true,
    },
  },
  {
    label: "Gender",
    description: "What is their typical gender?",
    name: "gender",
    type: "text",
    props: {
      type: "text",
      label: "Gender",
    },
  },
  {
    label: "Education Level",
    description: "Highest level of education completed?",
    name: "educationLevel",
    type: "text",
    props: {
      type: "text",
      label: "Education Level",
    },
  },
  {
    label: "Job Responsibilities",
    description: "What are some of their job responsibilities?",
    name: "jobResponsibilities",
    type: "text",
    props: {
      type: "text",
      multiline: true,
      rows: 4,
      label: "Job Responsibilities",
    },
  },
  {
    label: "Reports To",
    description: "Who do they report to in their organization?",
    name: "reportsTo",
    type: "text",
    props: {
      type: "text",
      label: "Reports To",
    },
  },
  {
    label: "Goals and Objectives",
    description: "What are their main goals or objectives?",
    name: "goalsAndObjectives",
    type: "text",
    props: {
      type: "text",
      label: "Goals and Objectives",
    },
  },
  {
    label: "Challenges",
    description: "What are some of their biggest challenges?",
    name: "challenges",
    type: "text",
    props: {
      type: "text",
      label: "Challenges",
    },
  },
  {
    label: "Primary Information Source",
    description: "What is their primary information source?",
    name: "primaryInformationSource",
    type: "text",
    props: {
      type: "text",
      label: "Primary Information Source",
    },
  },
];

export default function BuyerPersonaInfo() {
  const { siteId, personaId } = useParams();
  const { getStandardDoc } = useDb();
  const [persona, setPersona] = React.useState();

  const loadPersona = async () => {
    const p = await getStandardDoc("buyerPersonas", personaId);
    setPersona(p);
  };

  useEffect(() => {
    const unsubscribe = loadPersona();
    return unsubscribe;
  }, [siteId, personaId]);

  return !persona ? (
    <LinearProgress />
  ) : (
    <div>
      <Container>
        <BackLink override={`/targeting/${siteId}/`} />

        <InfoWizard
          steps={steps}
          siteId={siteId}
          title={persona.name}
          category={"buyerPersonaInfo"}
          collection="buyerPersonas"
          documentId={personaId}
        />
      </Container>
    </div>
  );
}
