import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { Alert } from "@mui/material";
import React from "react";
import { FcDocument } from "react-icons/fc";
import { Link } from "react-router-dom";

export default function ContentGeneration({ siteId }) {
  return (
    <Card>
      <CardHeader title="Content Generation" avatar={<FcDocument />} />
      <CardContent>
        <Alert severity="info">
          Based on the gaps in your current site content, we will periodically
          pre-generate content for you to review and publish here.
        </Alert>
      </CardContent>
      <CardActions>
        <Link to={`/generate-content/${siteId}`}>
          <Button startIcon={<Add />} color="primary">
            New Content
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
}
