import { Rocket } from "@mui/icons-material";
import { Alert, Button, CardActions } from "@mui/material";
import React, { useState } from "react";
import { functions } from "../../../../../firebase";
import { useDb } from "../../../../../contexts/DatabaseContext";

export default function SiteSetupComplete({ site }) {
  const [loading, setLoading] = useState(false);
  const { resetSiteLinks } = useDb();

  const handleAnalysisStart = async () => {
    setLoading(true);
    const beginSiteAnalysis = functions.httpsCallable("beginSiteAnalysis");
    const result = await beginSiteAnalysis({
      siteId: site.id,
    });
    if (result) {
      window.location.reload();
    }
  };

  const handleAnalysisRerun = async () => {
    setLoading(true);
    await resetSiteLinks(site.id);
    handleAnalysisStart();
  };

  if (site.analyzer_status === "Complete") {
    return (
      <>
        <Alert severity="success">Analysis Complete.</Alert>
        <CardActions>
          {/* <Button variant="outlined">Analysis Prompt</Button> */}
          <Button
            variant="outlined"
            onClick={handleAnalysisRerun}
            disabled={loading}
          >
            Re-Run
          </Button>
        </CardActions>
      </>
    );
  }

  if (site.analyzer_status === "Running") {
    return (
      <Alert severity="info">
        Your Site Analysis is in progress. Please check back later.
      </Alert>
    );
  }

  return (
    <Button
      onClick={handleAnalysisStart}
      disabled={loading}
      variant="contained"
      startIcon={<Rocket />}
      size="large"
    >
      Begin Site Analysis
    </Button>
  );
}
