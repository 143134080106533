import { Container } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import BackLink from "../../../shared/BackLink";
import InfoWizard from "../targeting/info-wizard/InfoWizard";

const steps = [
  {
    label: "Overview",
    description:
      "Explain your brand or organization in several sentences. Please describe the core products or services you provide.",
    name: "overview",
  },
  {
    label: "Company Goals",
    description:
      "What are your company’s key goals? In other words, how does your company define its success?",
    name: "companyGoals",
  },
  {
    label: "Team Goals",
    description: "What are your team’s key goals?",
    name: "teamGoals",
  },
  {
    label: "Obstacles",
    description: "What are the top three obstacles your organization faces?",
    name: "obstacles",
  },
  {
    label: "Target Audience",
    description:
      "Briefly describe who is your target audience? We'll do a deep dive into buyer personas later.",
    name: "targetAudience",
  },
  {
    label: "Target Geographic Locations",
    description: "What are your target geographic locations?",
    name: "targetGeographicLocations",
  },
  {
    label: "Product/Service Information",
    description:
      "How are your products/services solving your potential customer's problem?",
    name: "productInfo",
  },
  {
    label: "Value Proposition",
    description: "What is your primary value proposition?",
    name: "valueProposition",
  },
  {
    label: "Core Values",
    description: "What are the organization's core values?",
    name: "coreValues",
  },
  {
    label: "Common Customer Objections",
    description:
      "What are some of your common customer objections to buying your product/service?",
    name: "commonCustomerObjections",
  },
  {
    label: "Customer Loyalty",
    description: "Name the main reasons your customers remain loyal.",
    name: "customerLoyalty",
  },
];

export default function CompanyInfo() {
  const { siteId } = useParams();

  return (
    <div>
      <Container>
        <BackLink override={`/site/${siteId}/`} />
        <InfoWizard
          steps={steps}
          siteId={siteId}
          title="Company Information"
          category="companyInfo"
        />
      </Container>
    </div>
  );
}
