import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import PromptEditor from "./PromptEditor";

export default function PromptDialog({
  open,
  close,
  target,
  prompt,
  handlePromptChange,
  generation,
  wordCount,
  revenueRanges,
  employeeSizes,
  includeChallenges,
  includeIndustries,
  includeJobs,
  excludeIndustries,
  excludeJobs,
}) {
  const { topic, tone, format, buyerStage } = generation;

  const handleClose = () => {
    close();
  };

  const placeholders = {
    wordCount,
    topic,
    buyerPersona: includeJobs,
    buyerStage,
    tone,
    format,
    revenueRanges: revenueRanges || "Any",
    employeeSizes: employeeSizes || "Any",
    includeChallenges,
    industries: includeIndustries || "All",
    excludeIndustries: excludeIndustries || "None",
    excludeJobs: excludeJobs || "None",
  };

  const renderDynamicPrompt = (prompt) => {
    const replacedPrompt = prompt.replace(
      /{{(.*?)}}/g,
      (match, p1) => placeholders[p1] || match
    );
    return replacedPrompt.split("\n").map((line, index) => (
      <Typography key={index} component="p" style={{ margin: 0 }}>
        {line}
      </Typography>
    ));
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Prompt: {target}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <PromptEditor
              initialPrompt={prompt}
              onChange={handlePromptChange}
              placeholders={placeholders}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography color="primary" fontWeight="bold">
              Preview:
            </Typography>
            {renderDynamicPrompt(prompt)}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
