import { WebAsset } from "@mui/icons-material";
import { Avatar, Card, CardHeader } from "@mui/material";
import React from "react";

export default function ContentPreviewCard({ siteData }) {
  return (
    <Card>
      <CardHeader
        title={siteData.title}
        subheader={siteData.content}
        subheaderTypographyProps={{
          noWrap: true,
          overflow: "hidden",
          maxWidth: "350px",
        }}
        avatar={
          <Avatar>
            <WebAsset />
          </Avatar>
        }
        // TODO: Add preview button here that opens a full page preview of the text content
        // action={
        //   <IconButton>
        //     <Preview />
        //   </IconButton>
        // }
      />
    </Card>
  );
}
