import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";
import React from "react";
import { useAuth } from "../contexts/AuthContext";
import { DatabaseProvider } from "../contexts/DatabaseContext";
import PublicRoutes from "../routes/PublicRoutes";
// import AdminPage from "./admin/AdminPage"; // Uncomment this line to enable the Admin Page
import CustomerPage from "./customer/CustomerPage";

// Set your theme colors and styles below. Details on Theming here: https://material-ui.com/customization/theming/#theming

const contentDocBlue = "#4ab7c4";

const theme = createTheme({
  palette: {
    primary: {
      main: contentDocBlue,
      contrastText: "#fff",
    },
    mainMenuText: "#333",
  },
  typography: {
    button: {
      textTransform: "none",
    },
    h4: {
      fontWeight: 500,
      color: "#333",
      marginBottom: 15,
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 10,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: contentDocBlue,
          color: "#fff",
          marginBottom: 10,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: contentDocBlue,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          boxShadow: `0px 4px 0px ${contentDocBlue}`,
          border: `1px solid ${contentDocBlue}`,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: 10,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: contentDocBlue,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: contentDocBlue,
        },
        primary: {
          fontWeight: "bold",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingTop: 0,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          color: contentDocBlue,
          fontWeight: "bold",
        },
        avatar: {
          color: contentDocBlue,
        },
        action: {
          color: contentDocBlue,
        },
      },
    },
  },
});

export default function Wrapper() {
  const { currentUser } = useAuth();
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <DatabaseProvider>
          {currentUser ? <CustomerPage /> : <PublicRoutes />}
        </DatabaseProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
