import { Typography } from "@mui/material";
import React from "react";

export default function Integrations() {
  return (
    <div>
      <h1>Access Blocked</h1>
      <Typography>You are not authorized to access this area.</Typography>
    </div>
  );
}
