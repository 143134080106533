import React, { useContext, useState, useEffect } from "react";
import { auth, db } from "../firebase";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  async function logOut() {
    return await auth.signOut().then(() => {
      window.location.reload();
    });
  }

  function logIn(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function forgotPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  async function signUp(email, password, data) {
    const userCred = await auth.createUserWithEmailAndPassword(email, password);
    let userData = data;
    delete userData.password;
    const orgId = await createOrgRecord(
      userCred.user.uid,
      data.companyName || `${data.firstName} ${data.lastName}`
    );
    await createUserRecord(userCred.user.uid, { email, ...userData, orgId });
  }

  function createUserRecord(uid, data) {
    return db
      .collection("users")
      .doc(uid)
      .set({
        ...data,
        created_time: new Date(),
        orgs: [data.orgId],
      });
  }

  async function createOrgRecord(uid, name) {
    const org = await db.collection("orgs").add({
      name,
      owner: uid,
      created_time: new Date(),
    });
    return org.id;
  }

  async function getUserRecord(uid) {
    return await db
      .collection("users")
      .doc(uid)
      .get()
      .then((doc) => {
        return doc.data();
      });
  }

  function getUserIdToken() {
    return auth.currentUser.getIdToken();
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userDoc = await getUserRecord(user.uid);
        setCurrentUser({ ...user, ...userDoc });
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signUp,
    logOut,
    logIn,
    forgotPassword,
    getUserIdToken,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
