import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDb } from "../../../../../contexts/DatabaseContext";
import { Container, LinearProgress } from "@mui/material";
import BackLink from "../../../../shared/BackLink";
import TopicIdeas from "./TopicIdeas";

export default function TopicGenerateContainer() {
  const { siteId } = useParams();
  const { getStandardDoc, getOrgCollectionWithFilterSync } = useDb();

  const [site, setSite] = useState(null);
  const [model, setModel] = useState();
  const [selections, setSelections] = useState([]);

  const loadSite = async () => {
    const s = await getStandardDoc("sites", siteId);
    const m = await getStandardDoc("models", s.model);
    const sels = await getOrgCollectionWithFilterSync(
      "selections",
      "modelId",
      s.model
    );
    setSelections(sels);
    setModel(m);
    setSite(s);
  };

  useEffect(() => {
    const unsubscribe = loadSite();
    return unsubscribe;
  }, [siteId]);

  return !site ? (
    <LinearProgress />
  ) : (
    <div>
      <Container>
        <BackLink />
        <TopicIdeas siteId={siteId} selections={selections} />
      </Container>
    </div>
  );
}
