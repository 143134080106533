import React from "react";
import RMFDataGrid from "../../../shared/data-grid/RMFDataGrid";
import { Chip, IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useDb } from "../../../../contexts/DatabaseContext";

export default function InfoTable({
  infoTypeCollection,
  siteId,
  additionalColumns = [],
}) {
  const { GetOrgCollectionWithFilter } = useDb();
  const types = GetOrgCollectionWithFilter(
    infoTypeCollection,
    "siteId",
    siteId
  );

  const rows =
    types &&
    types.map((info) => ({
      ...info,
      complete: info[`${infoTypeCollection}InfoComplete`],
    }));

  const columns = [
    {
      field: "url",
      headerName: "",
      width: 40,
      renderCell: (params) => {
        return (
          <IconButton size="small">
            <Edit />
          </IconButton>
        );
      },
    },
    { field: "name", headerName: "Name", width: 250 },
    {
      field: "status",
      headerName: "Status",
      width: 90,
      renderCell: (params) => {
        return params.row.status ? (
          <Chip label="Complete" color="primary" />
        ) : (
          <Chip label="Draft" />
        );
      },
    },
    ...additionalColumns,
  ];
  return <div>{types && <RMFDataGrid data={rows} columns={columns} />}</div>;
}
