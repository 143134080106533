import { CopyAll, Person } from "@mui/icons-material";
import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Skeleton,
  Button,
  Box,
} from "@mui/material";
import React from "react";
import { FaRobot } from "react-icons/fa";
import { GrSystem } from "react-icons/gr";

export default function MsgTemplate({ msg, copied }) {
  const roles = {
    user: {
      title: "User",
      avatar: (
        <Avatar>
          <Person />
        </Avatar>
      ),
    },
    assistant: {
      title: "Assistant",
      avatar: (
        <Avatar>
          <FaRobot />
        </Avatar>
      ),
    },
    system: {
      title: "System",
      avatar: (
        <Avatar>
          <GrSystem />
        </Avatar>
      ),
    },
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(msg.content);
    copied();
  };

  return (
    <div>
      <Card>
        <CardHeader
          title={roles[msg.role].title}
          avatar={roles[msg.role].avatar}
          action={
            msg.writing ? (
              <CircularProgress />
            ) : (
              <Button onClick={handleCopy} startIcon={<CopyAll />}>
                Copy
              </Button>
            )
          }
        />
        <CardContent>
          <span className="msg-content">{msg.content}</span>

          {msg.writing && <Skeleton variant="text" sx={{ fontSize: "1rem" }} />}
          {!msg.writing && msg.role === "assistant" && (
            <Box textAlign="center">
              <br />
              <Button onClick={handleCopy} startIcon={<CopyAll />}>
                Copy
              </Button>
            </Box>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
