import { Container, LinearProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useDb } from "../../../../contexts/DatabaseContext";
import BackLink from "../../../shared/BackLink";
import InfoWizard from "./info-wizard/InfoWizard";

export default function InfoBuilder({
  steps,
  infoTypeCollection,
  documentId,
  siteId,
}) {
  const { getStandardDoc } = useDb();
  const [info, setInfo] = React.useState();

  const loadInfo = async () => {
    console.log(infoTypeCollection, documentId);
    const infoDoc = await getStandardDoc(infoTypeCollection, documentId);
    setInfo(infoDoc);
  };

  useEffect(() => {
    const unsubscribe = loadInfo();
    return unsubscribe;
  }, [siteId, documentId]);

  return !info ? (
    <LinearProgress />
  ) : (
    <div>
      <Container>
        <BackLink override={`/targeting/${siteId}/`} />

        <InfoWizard
          steps={steps}
          siteId={siteId}
          title={info.name}
          category={infoTypeCollection}
          collection={infoTypeCollection}
          documentId={documentId}
        />
      </Container>
    </div>
  );
}
