import React from "react";
import { Alert, Grid } from "@mui/material";
import CompanyChecklistItem from "../company_info/CompanyChecklistItem";

export default function SiteSetupPending({ site }) {
  return (
    <div>
      <Alert severity="info">
        We need some info from you before we can begin analyzing your site.
        Please complete the items below to begin.
      </Alert>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <CompanyChecklistItem
            siteId={site.id}
            field={"elevator_pitch"}
            title="Elevator Pitch"
            subheader="Tell us about your company"
            checked={site.setup_steps?.elevator_pitch}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CompanyChecklistItem
            siteId={site.id}
            field={"what_you_do"}
            title="What do you do?"
            subheader="Enter a brief description of your product and services"
            checked={site.setup_steps?.what_you_do}
          />
        </Grid>
      </Grid>
    </div>
  );
}
