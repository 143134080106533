import { Slider } from "@mui/material";
import React from "react";

export default function InfoSlider(props) {
  const { label, name, defaultValue, value, step, min, max, onChange, type } =
    props;
  const handleChange = (event, newValue) => {
    if (onChange) {
      onChange(name, newValue, type);
    }
  };

  return (
    <div>
      <Slider
        defaultValue={defaultValue || [34, 56]}
        valueLabelDisplay="on"
        value={value || defaultValue}
        onChange={handleChange}
        step={step || 1}
        marks
        min={min || 0}
        max={max || 100}
      />
    </div>
  );
}
