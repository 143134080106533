import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Typography,
  Container,
} from "@mui/material";
import React, { useState } from "react";
import { functions } from "../../../firebase";
import { FaCcStripe } from "react-icons/fa";
import { useAuth } from "../../../contexts/AuthContext";
import { Link } from "react-router-dom";
import { CreditCard, Person } from "@mui/icons-material";

export default function Account() {
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();

  const openStripePortal = async () => {
    setLoading(true);
    const functionRef = functions.httpsCallable(
      "ext-firestore-stripe-payments-createPortalLink"
    );
    const { data } = await functionRef({
      returnUrl: `${window.location.origin}/account`,
      locale: "auto", // Optional, defaults to "auto"
      // configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
    });
    // setLoading(false);
    window.location.assign(data.url);
  };

  return (
    <div>
      <Container>
        <Typography variant="h4">Your Account</Typography>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardHeader
                    title="Account Info"
                    avatar={
                      <Avatar>
                        <Person />
                      </Avatar>
                    }
                  />
                  <CardContent>
                    <Typography>
                      {currentUser.firstName} {currentUser.lastName}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardHeader
                    title="Billing"
                    avatar={
                      <Avatar>
                        <CreditCard />
                      </Avatar>
                    }
                  />
                  <CardContent>
                    {!currentUser.stripeLink ? (
                      <div>
                        <Link to="/select-plan">
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<FaCcStripe />}
                          >
                            Select Plan
                          </Button>
                        </Link>
                      </div>
                    ) : !loading ? (
                      <Button
                        onClick={openStripePortal}
                        startIcon={<FaCcStripe />}
                      >
                        Access Billing Portal
                      </Button>
                    ) : (
                      <div>
                        <LinearProgress />
                        <Typography>
                          Loading Billing, please wait ...
                        </Typography>
                      </div>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
