export function paragraphize(string, wordsPerLine) {
    if(typeof string === 'string') {
        if(typeof wordsPerLine === 'undefined') {
         wordsPerLine = 10;   
        }
        
        var wordCount = 0, newParagraph = "";

        string.split(/\s/).forEach(function(word) {
            wordCount++;
            
            if(wordCount <= wordsPerLine) {
             newParagraph += word + ' ';
            }
            
            else {
             wordCount = 0;
             newParagraph += '\n\n' + word + ' ';
            }
        });
        
        return newParagraph.trim()
    }
    
    throw TypeError('Argument 1 must be a string.');
}

export function snakeCaseText(text) {
  // replace spaces with underscores
  text = text.replace(/\s+/g, "_");
  // convert uppercase to lowercase
  text = text.toLowerCase();
  return text;
};