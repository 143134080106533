import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import React, { useState } from "react";
import NewMember from "./NewMember";

export default function Team() {
  const [addOpen, setAddOpen] = useState(false);
  return (
    <div>
      <h1>Team</h1>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        size="small"
        onClick={() => {
          setAddOpen(true);
        }}
      >
        New Member
      </Button>

      <NewMember
        open={addOpen}
        close={() => {
          setAddOpen(false);
        }}
      />
    </div>
  );
}
