import {
  Check,
  CheckCircle,
  Error,
  HourglassEmptyRounded,
} from "@mui/icons-material";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import React from "react";
import RMFDataGrid from "../../../shared/data-grid/RMFDataGrid";
import { AiOutlineLink } from "react-icons/ai";

export default function ReviewTable({ links }) {
  const rows =
    links &&
    links.map((link) => ({
      ...link,
      title: link.title || link.url,
    }));

  const columns = [
    {
      field: "reviewed",
      headerName: "Reviewed",
      width: 80,
      renderCell: (params) => {
        return params.row.reviewed ? (
          <Avatar style={{ background: "#1bc39a", height: 30, width: 30 }}>
            <Check />{" "}
          </Avatar>
        ) : (
          <Avatar style={{ height: 30, width: 30 }}>
            <HourglassEmptyRounded />
          </Avatar>
        );
      },
    },
    { field: "title", headerName: "Page Title", width: 300 },
    {
      field: "url",
      headerName: "URL",
      width: 80,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.url}>
            <IconButton
              href={params.row.url}
              target="_blank"
              rel="noopener noreferrer"
              size="large">
              <AiOutlineLink />
            </IconButton>
          </Tooltip>
        );
      },
    },
    { field: "content_type", headerName: "Content Type", width: 150 },
    { field: "persona", headerName: "Persona", width: 150 },
    { field: "buyer_journey", headerName: "B. Journey", width: 150 },
  ];

  return (
    <div>
      <RMFDataGrid data={rows} columns={columns} />
    </div>
  );
}
