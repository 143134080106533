import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import Select from "react-select";
import { useDb } from "../../../contexts/DatabaseContext";
import { selectFieldProps } from "../../shared/constants";
import ContentPreviewCard from "../site-detail/content-generation/reference-content/ContentPreviewCard";

export default function SelectExistingContent({
  open,
  close,
  siteId,
  sendLink,
}) {
  const { GetLinksForSite } = useDb();
  const links = GetLinksForSite(siteId);
  const [selectedLink, setSelectedLink] = useState();

  const handleClose = () => {
    setSelectedLink(null);
    close();
  };

  const handleSelect = (v, e) => {
    setSelectedLink(v);
  };

  const handleSubmit = () => {
    sendLink({
      title: selectedLink.title,
      content: selectedLink.content,
    });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Select Example Content</DialogTitle>
      <DialogContent>
        <Select
          options={links}
          placeholder="Select Content"
          {...selectFieldProps}
          name="content"
          onChange={handleSelect}
          className="rmf-select"
        />
        {selectedLink && (
          <div>
            <ContentPreviewCard siteData={selectedLink} />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          disabled={!selectedLink}
          onClick={handleSubmit}
          color="primary"
          variant="contained"
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
}
