import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { Home } from "@mui/icons-material";
import React from "react";
import { MdCoffee } from "react-icons/md";

export default function EntrySuccess({ doAnother, goHome, open, close }) {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={close}>
      <DialogTitle>Data Saved Successfully</DialogTitle>
      <DialogActions>
        <Button
          startIcon={<MdCoffee />}
          variant="contained"
          color="primary"
          onClick={doAnother}
        >
          Another !
        </Button>
        <Button
          startIcon={<Home />}
          variant="contained"
          color="primary"
          onClick={goHome}
        >
          Home
        </Button>
      </DialogActions>
    </Dialog>
  );
}
