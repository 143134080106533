import { Edit, PersonAdd } from "@mui/icons-material";
import { Button, Card, CardActions, Chip, IconButton } from "@mui/material";
import React from "react";
import { useDb } from "../../../../contexts/DatabaseContext";
import NewPersonaDialog from "./NewPersonaDialog";
import RMFDataGrid from "../../../shared/data-grid/RMFDataGrid";

export default function BuyerPersonas({ siteId }) {
  const [addOpen, setAddOpen] = React.useState(false);
  const { GetOrgCollectionWithFilter } = useDb();
  const personas = GetOrgCollectionWithFilter(
    "buyerPersonas",
    "siteId",
    siteId
  );

  const rows =
    personas &&
    personas.map((persona) => ({
      ...persona,
      complete: persona.buyerPersonaInfoComplete,
    }));

  const columns = [
    {
      field: "url",
      headerName: "",
      width: 40,
      renderCell: (params) => {
        return (
          <IconButton size="small">
            <Edit />
          </IconButton>
        );
      },
    },
    { field: "name", headerName: "Name", width: 350 },
    {
      field: "status",
      headerName: "Status",
      width: 350,
      renderCell: (params) => {
        return params.row.status ? (
          <Chip label="Complete" color="primary" />
        ) : (
          <Chip label="Draft" />
        );
      },
    },
  ];

  return (
    <div>
      <Button onClick={() => setAddOpen(true)} startIcon={<PersonAdd />}>
        Add Persona Attributes
      </Button>
      {personas && <RMFDataGrid data={rows} columns={columns} />}

      <NewPersonaDialog
        siteId={siteId}
        open={addOpen}
        close={() => setAddOpen(false)}
      />
    </div>
  );
}
