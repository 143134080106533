import React, { useContext, useState, useEffect } from "react";
import { db } from "../firebase";
import { useAuth } from "./AuthContext";
import Papa from "papaparse";

const DatabaseContext = React.createContext();

export function useDb() {
  return useContext(DatabaseContext);
}

export function DatabaseProvider({ children }) {
  const { currentUser } = useAuth();

  function parseCSV(csvFile) {
    return new Promise((res, rej) => {
      try {
        Papa.parse(csvFile, {
          skipEmptyLines: true,
          complete: function (results) {
            const linkArray = buildLinkArray(results.data);
            res(linkArray);
          },
        });
      } catch (err) {
        rej(err);
      }
    });
  }

  function jsonToCSV(jsonArray) {
    const csv = Papa.unparse(jsonArray);
    return csv;
  }

  function buildLinkArray(data) {
    const linkArray = data.map((link) => link[0]);
    return linkArray;
  }

  async function createBatches(records) {
    return new Promise(async (resolve, reject) => {
      if (records.length < 500) {
        const smallArray = [];
        await records.forEach((row) => {
          smallArray.push(row);
          resolve({ data: smallArray, nested: false });
        });
      } else {
        const chunkedArray = [];
        for (let i = 0; i < records.length; i++) {
          const last = chunkedArray[chunkedArray.length - 1];
          if (!last || last.length === 500) {
            chunkedArray.push([records[i]]);
          } else {
            last.push(records[i]);
          }
        }
        resolve({ data: chunkedArray, nested: true });
      }
    });
  }

  async function createLinkDocuments(links, siteId) {
    return new Promise(async (resolve, reject) => {
      const firstLink = links[0];
      // There can be more than 500 so create batches
      try {
        const batches = await createBatches(links);
        if (batches.nested) {
          for (let i = 0; i < batches.data.length; i++) {
            // const wait = setTimeout(async () => {}, 2000);
            await submitBatch(batches.data[i], siteId);
          }
        } else {
          await submitBatch(batches.data, siteId);
        }
        const cleanLinkId = removeSpecialChars(firstLink);
        await db.collection("scrapeQueue").add({
          siteId,
          link: `${siteId}_${cleanLinkId}`,
          created_at: new Date(),
          owner: currentUser.uid,
          url: firstLink,
        });
        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }

  function removeSpecialChars(str) {
    return str.replace(/[/:]/g, "");
  }

  async function submitBatch(linkBatch, siteId) {
    return new Promise(async (resolve, reject) => {
      const created_at = new Date();
      await linkBatch.forEach(async (link) => {
        const cleanLinkId = removeSpecialChars(link);
        const batch = db.batch();
        const dbRef = db.collection("links").doc(`${siteId}_${cleanLinkId}`);
        batch.set(
          dbRef,
          {
            url: link,
            created_at,
            siteId,
            orgId: currentUser.orgId,
            owner: currentUser.uid,
            processed: false,
            reviewed: false,
          },
          { merge: true }
        );
        await batch.commit();
      });
      resolve();
    });
  }

  function GetLinksForSite(siteId) {
    const [links, setLinks] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("links")
        // .where("owner", "==", currentUser.uid)
        .where("siteId", "==", siteId)
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: {
              ...doc.data(),
              id: doc.id,
            },
            label: doc.data().title || "NA",
          }));
          setLinks(data);
        });
      return unsubscribe;
    }, [siteId]);
    return links;
  }

  async function createTestAiDoc() {
    // Copy a doc from the links collection and create it in the openai collection
    // This is for testing purposes only
    const testDoc = await db
      .collection("links")
      .doc("httpswemanageyoursite.com")
      .get();
    const testDocData = testDoc.data();

    return db.collection("openai").add({
      ...testDocData,
      timestamp: new Date(),
    });
  }

  async function createSite(data) {
    const newSite = await db.collection("sites").add({
      ...data,
      created_at: new Date(),
      owner: currentUser.uid,
      orgId: currentUser.orgId,
    });

    // Set the default demographic
    await db.collection("demographics").add({
      createdAt: new Date(),
      industry: "Default",
      org: currentUser.orgId,
      owner: currentUser.uid,
      siteId: newSite.id,
    });

    return newSite.id;
  }

  function GetSites() {
    const [sites, setSites] = useState([]);
    useEffect(() => {
      const unsubscribe = db
        .collection("sites")
        .where("orgId", "in", currentUser.orgs)
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSites(data);
        });
      return unsubscribe;
    }, []);
    return sites;
  }

  async function getSite(siteId) {
    const siteSnap = await db.collection("sites").doc(siteId).get();
    const site = {
      ...siteSnap.data(),
      id: siteSnap.id,
    };

    return site;
  }

  async function createSubscriptionCheckoutSession(price) {
    const createSession = await db
      .collection("users")
      .doc(currentUser.uid)
      .collection("checkout_sessions")
      .add({
        price,
        success_url: `${window.location.origin}/verify-subscription`,
        cancel_url: `${window.location.origin}/payment-failed`,
        allow_promotion_codes: true,
      });
    createSession.onSnapshot((snap) => {
      const { url } = snap.data();
      if (url) window.location.assign(url);
    });
  }

  async function getFirstDocOfQueryAdmin(collection, whereField, whereValue) {
    const snapshot = await db
      .collection(collection)
      .where(whereField, "==", whereValue)
      .limit(1)
      .get();
    if (snapshot.empty) return null;
    else
      return {
        id: snapshot.docs[0].id,
        ...snapshot.docs[0].data(),
      };
  }

  async function getReviewLinkForSite(siteId) {
    const snapshot = await db
      .collection("links")
      .where("reviewed", "==", false)
      .where("siteId", "==", siteId)
      .limit(1)
      .get();
    if (snapshot.empty) return null;
    else
      return {
        id: snapshot.docs[0].id,
        ...snapshot.docs[0].data(),
      };
  }

  function GetOrgCollection(collection) {
    const [data, setData] = useState();
    useEffect(() => {
      const unsubscribe = db
        .collection(collection)
        .where("org", "==", currentUser.orgId)
        .onSnapshot((snapshot) => {
          const docs = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            value: doc.id,
            label: doc.data().name || doc.id,
          }));
          setData(docs);
        });
      return unsubscribe;
    }, [collection]);
    return data;
  }

  function GetOrgCollectionWithFilter(collection, filterName, filterValue) {
    const [data, setData] = useState();
    useEffect(() => {
      const unsubscribe = db
        .collection(collection)
        .where("org", "in", currentUser.orgs)
        .where(filterName, "==", filterValue)
        .onSnapshot((snapshot) => {
          const docs = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setData(docs);
        });
      return unsubscribe;
    }, [collection, filterName, filterValue]);
    return data;
  }

  async function getOrgCollectionWithFilterSync(
    collection,
    filterName,
    filterValue
  ) {
    const snapshot = await db
      .collection(collection)
      .where("org", "in", currentUser.orgs)
      .where(filterName, "==", filterValue)
      .get();
    const docs = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return docs;
  }

  async function addStandardDoc(collection, data) {
    const model = await db.collection(collection).add({
      ...data,
      createdAt: new Date(),
      owner: currentUser.uid,
      org: currentUser.orgId,
    });
    return model.id;
  }

  async function getStandardDoc(collection, docId) {
    const doc = await db.collection(collection).doc(docId).get();
    return {
      id: doc.id,
      ...doc.data(),
    };
  }

  function updateStandardDoc(collection, docId, data) {
    return db
      .collection(collection)
      .doc(docId)
      .update({
        ...data,
        updatedAt: new Date(),
        updatedBy: currentUser.uid,
      });
  }

  function GetCollectionFilter(collection, filterName, filterValue) {
    const [data, setData] = useState();
    useEffect(() => {
      const unsubscribe = db
        .collection(collection)
        .where(filterName, "==", filterValue)
        .onSnapshot((snapshot) => {
          const docs = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setData(docs);
        });
      return unsubscribe;
    }, [collection]);
    return data;
  }

  function deleteStandardDoc(collection, docId) {
    return db.collection(collection).doc(docId).delete();
  }

  async function iwanReviewFunction() {
    const snapshot = await db.collection("links").get();

    const promiseArray = snapshot.docs.map(async (doc) => {
      return await doc.ref.update({
        reviewed: false,
      });
    });

    return await Promise.all(promiseArray);
  }

  async function checkIfSiteHasPersonas(siteId) {
    const snapshot = await db
      .collection("buyerPersonas")
      .where("org", "==", currentUser.orgId)
      .where("siteId", "==", siteId)
      .get();
    return !snapshot.empty;
  }

  function setStandardDoc(collection, docId, data) {
    return db
      .collection(collection)
      .doc(docId)
      .set(
        {
          ...data,
          createdAt: new Date(),
          owner: currentUser.uid,
          org: currentUser.orgId,
        },
        { merge: true }
      );
  }

  async function removeDemographic(siteId, industry) {
    const querySnap = await db
      .collection("demographics")
      .where("siteId", "==", siteId)
      .where("industry", "==", industry)
      .get();
    if (!querySnap.empty) {
      const deletePromises = querySnap.docs.map((demoDoc) => {
        return demoDoc.ref.delete();
      });
      await Promise.all(deletePromises);
    }
  }

  async function loadDemographicsIndustriesDropdown(siteId) {
    const querySnap = await db
      .collection("demographics")
      .where("siteId", "==", siteId)
      .get();
    if (!querySnap.empty) {
      const industryValues = querySnap.docs.map((demoDoc) => ({
        label: demoDoc.data().industry,
        value: demoDoc.data().industry,
      }));
      return industryValues;
    }
  }

  async function getSiteDefaultDemographic(siteId) {
    const querySnap = await db
      .collection("demographics")
      .where("siteId", "==", siteId)
      .where("default", "==", true)
      .limit(1)
      .get();

    return querySnap.docs[0].data();
  }

  async function getSiteDefaultFirmographic(siteId) {
    const querySnap = await db
      .collection("firmographics")
      .where("siteId", "==", siteId)
      .limit(1)
      .get();

    return querySnap.docs[0].data();
  }

  async function resetSiteLinks(siteId) {
    // Get all the site links
    const linksSnapshot = await db
      .collection("links")
      .where("siteId", "==", siteId)
      .get();

    const links = linksSnapshot.docs;

    const batches = await createBatches(links);

    if (batches.nested) {
      for (const batchLinks of batches.data) {
        const batch = db.batch();
        batchLinks.forEach((link) => {
          const linkRef = link.ref;
          batch.update(linkRef, {
            processed: false,
          });
        });
        await batch.commit();
      }
    } else {
      const batch = db.batch();
      batches.data.forEach((link) => {
        const linkRef = link.ref;
        batch.update(linkRef, {
          processed: false,
        });
      });
      await batch.commit();
    }
  }

  const value = {
    parseCSV,
    createLinkDocuments,
    GetLinksForSite,
    createTestAiDoc,
    createSite,
    GetSites,
    getSite,
    createSubscriptionCheckoutSession,
    getFirstDocOfQueryAdmin,
    GetOrgCollection,
    GetOrgCollectionWithFilter,
    addStandardDoc,
    getStandardDoc,
    updateStandardDoc,
    GetCollectionFilter,
    iwanReviewFunction,
    deleteStandardDoc,
    getReviewLinkForSite,
    getOrgCollectionWithFilterSync,
    checkIfSiteHasPersonas,
    jsonToCSV,
    setStandardDoc,
    removeDemographic,
    loadDemographicsIndustriesDropdown,
    getSiteDefaultDemographic,
    getSiteDefaultFirmographic,
    resetSiteLinks,
  };

  return (
    <DatabaseContext.Provider value={value}>
      {children}
    </DatabaseContext.Provider>
  );
}
