import { Card, CardActionArea, CardHeader } from "@mui/material";
import React from "react";
import { FcDisplay } from "react-icons/fc";
import { Link } from "react-router-dom";

export default function ModelCard({ model }) {
  return (
    <Card>
      <CardActionArea>
        <Link to={`/model/${model.id}`}>
          <CardHeader
            title={model.name}
            subheader={model.modelType}
            avatar={<FcDisplay />}
          />
        </Link>
      </CardActionArea>
    </Card>
  );
}
