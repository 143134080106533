import React from "react";
import BackLink from "../../../../shared/BackLink";
import { Link, useParams } from "react-router-dom";
import { useDb } from "../../../../../contexts/DatabaseContext";
import RMFDataGrid from "../../../../shared/data-grid/RMFDataGrid";
import { Button, Chip, Container, Typography } from "@mui/material";
import { Add, Check, NoteAlt, PublishedWithChanges } from "@mui/icons-material";

export default function ContentManager() {
  const { siteId } = useParams();
  const { GetOrgCollectionWithFilter } = useDb();
  const content = GetOrgCollectionWithFilter("generate", "siteId", siteId);

  const contentStatuses = {
    draft: <Chip icon={<NoteAlt />} label={"Draft"} />,
    published: <Chip color="primary" icon={<Check />} label={"Published"} />,
    scheduled: (
      <Chip
        color="primary"
        icon={<PublishedWithChanges />}
        label={"Scheduled"}
      />
    ),
  };

  const rows =
    content &&
    content.map((c, i) => ({
      ...c,
      created: new Date(c.createdAt.seconds * 1000).toLocaleDateString(),
    }));

  const columns = [
    {
      field: "topic",
      headerName: "Topic",
      width: 400,
      renderCell: (params) => {
        return (
          <Link
            to={`/content-creator/${siteId}/${params.row.id}`}
            style={{
              width: 380,
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            <Typography fontSize={14} fontWeight="bold" color="primary">
              {params.row.topic}
            </Typography>
          </Link>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => {
        return contentStatuses[params.row.status || "draft"];
      },
    },
    {
      field: "created",
      headerName: "Created",
      width: 150,
    },
  ];
  return (
    <div>
      <Container>
        <BackLink override={`/site/${siteId}`} label="Site Overview" />
        <h1>Manage Content</h1>
        <Link to={`/generate-content/${siteId}`}>
          <Button startIcon={<Add />} variant="contained">
            Create New Content
          </Button>
        </Link>

        <RMFDataGrid data={rows} columns={columns} />
      </Container>
    </div>
  );
}
