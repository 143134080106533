import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BackLink from "../../shared/BackLink";
import Select from "react-select";
import { selectFieldProps } from "../../shared/constants";
import SelectExistingContent from "./SelectExistingContent";
import { useParams } from "react-router-dom";
import { Info } from "@mui/icons-material";
import { useDb } from "../../../contexts/DatabaseContext";
import { functions } from "../../../firebase";
import { Alert } from "@mui/material";

const personas = [
  { value: "endUser", label: "End User" },
  { value: "researcher", label: "Researcher" },
  { value: "decisionMaker", label: "Decision Maker" },
  { value: "financialAuthority", label: "Financial Authority" },
  { value: "other", label: "Other" },
  { value: "allPersonas", label: "All Personas" },
  { value: "na", label: "NA" },
];

const buyerJourney = [
  { value: "learn", label: "Learn" },
  { value: "solve", label: "Solve" },
  { value: "compare", label: "Compare" },
  { value: "purchase", label: "Purchase" },
  { value: "loyalty", label: "Loyalty" },
];

const contentTypes = [
  { value: "bannerAd", label: "Banner Ad" },
  { value: "blogPost", label: "Blog Post" },
  { value: "caseStudy", label: "Case Study" },
  { value: "directMail", label: "Direct Mail" },
  { value: "email", label: "Email" },
  { value: "event", label: "Event" },
  { value: "guide", label: "Guide" },
  { value: "infographic", label: "Infographic" },
  { value: "pressRelease", label: "Press Release" },
  { value: "print", label: "Print" },
  { value: "productBrief", label: "Product Brief" },
  { value: "radio", label: "Radio" },
  { value: "searchAd", label: "Search Ad" },
  { value: "socialAd", label: "Social Ad" },
  { value: "socialMediaPost", label: "Social Media Post" },
  { value: "telemarketing", label: "Telemarketing" },
  { value: "tv", label: "TV" },
  { value: "video", label: "Video" },
  { value: "webinar", label: "Webinar" },
  { value: "webPage", label: "Web Page" },
  { value: "whitepaper", label: "Whitepaper" },
];

export default function GenerateContent() {
  const { siteId } = useParams();
  const { getSite } = useDb();
  const [site, setSite] = useState();
  const [selection, setSelection] = useState({
    persona: null,
    buyerJourney: null,
    contentType: null,
    maxWords: 500, // TODO: Do we let the user pick the article length?
  });

  const [contentExample, setContentExample] = useState(null);
  const [selectOpen, setSelectOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [generatedContent, setGeneratedContent] = useState();

  const handleSelect = (v, e) => {
    setSelection({ ...selection, [e.name]: v.value });
  };

  const receiveLink = (link) => {
    setContentExample(link);
  };

  const retrieveSite = async () => {
    const s = await getSite(siteId);
    setSite(s);
  };

  useEffect(() => {
    const unsubscribe = retrieveSite();
    return unsubscribe;
  }, [siteId]);

  const handleGenerate = async () => {
    const createPageContent = functions.httpsCallable("createPageContent");
    const submission = {
      siteId,
      buyer_journey: selection.buyerJourney,
      content_type: selection.contentType,
      persona: selection.persona,
      contentExample,
      services: site.services,
      industry: site.industry,
      companyName: site.companyName,
    };
    setLoading(true);
    try {
      const result = await createPageContent(submission);
      setLoading(false);
      setGeneratedContent(result.data);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div>
      <Typography variant="h4">Generate Content</Typography>
      <BackLink />
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader title="Generate Content" />
            <CardContent>
              <Select
                placeholder="Select Persona"
                className="rmf-select"
                options={personas}
                {...selectFieldProps}
                name="persona"
                onChange={handleSelect}
              />
              <Select
                placeholder="Select Buyer Journey"
                className="rmf-select"
                options={buyerJourney}
                {...selectFieldProps}
                name="buyerJourney"
                onChange={handleSelect}
              />
              <Select
                placeholder="Select Content Type"
                className="rmf-select"
                options={contentTypes}
                {...selectFieldProps}
                name="contentType"
                onChange={handleSelect}
              />
              <br />

              {contentExample && (
                <Card>
                  <CardHeader
                    title={contentExample.title}
                    avatar={<Info />}
                    subheader={
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href={contentExample.url}
                        style={{
                          textDecoration: "underline",
                          color: "#1876d2",
                          fontWeight: "bold",
                        }}
                      >
                        {contentExample.url}
                      </a>
                    }
                  />
                  <CardContent>
                    <Typography>
                      Buyer Journey: {contentExample.buyer_journey}
                    </Typography>
                    <Typography>
                      Content Type: {contentExample.content_type}
                    </Typography>
                    <Typography>Persona: {contentExample.persona}</Typography>
                  </CardContent>
                </Card>
              )}
              <br />
              <Alert severity="info">
                You can optionally choose one of your existing articles to use
                as an example for the content you want to generate. This can
                give the AI a better idea of what you're going for.
                <br />
                <br />
                <Button
                  onClick={() => setSelectOpen(true)}
                  variant="outlined"
                  color="primary"
                  size="small"
                >
                  Select Content Example
                </Button>
              </Alert>
            </CardContent>
            <CardActions>
              <Button
                disabled={!site || loading}
                onClick={handleGenerate}
                variant="contained"
                color="primary"
              >
                Generate Content
              </Button>
              {loading && (
                <div>
                  <CircularProgress size={24} className="buttonProgress" /> -
                  Please Wait, this may take up to 2 minutes.
                </div>
              )}
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card>
            <CardHeader title="Content Preview" />
            <CardContent>
              {generatedContent && (
                <div>
                  <Typography className="css-fix">
                    {generatedContent}
                  </Typography>
                  <br />
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <SelectExistingContent
        open={selectOpen}
        close={() => setSelectOpen(false)}
        siteId={siteId}
        sendLink={receiveLink}
      />
    </div>
  );
}
