import { Button, Container, Grid, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import React, { useState } from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import NewSite from "./NewSite";
import SiteCard from "./SiteCard";

export default function Sites() {
  const [open, setOpen] = useState(false);
  const { GetSites } = useDb();
  const sites = GetSites();

  return (
    <Container>
      <div>
        <Typography variant="h4">Your Sites</Typography>
        <Button
          startIcon={<Add />}
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          New Site
        </Button>
        <div style={{ marginTop: 10 }}>
          <Grid container spacing={3}>
            {sites &&
              sites.map((site) => (
                <Grid item xs={12} md={4} key={site.id}>
                  <SiteCard site={site} />
                </Grid>
              ))}
          </Grid>
        </div>

        <NewSite open={open} close={() => setOpen(false)} />
      </div>
    </Container>
  );
}
