import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useDb } from "../../../../contexts/DatabaseContext";
import { textFieldProps } from "../../../shared/constants";
import { useHistory } from "react-router-dom";
import { ArrowForward } from "@mui/icons-material";
import { baseModelDefaults } from "./utilities/baseModelDefaults";
import { snakeCaseText } from "../../../shared/utils/textFormattingUtils";

export default function AddModelName({ open, close, modelType }) {
  const [data, setData] = useState({});
  const { addStandardDoc } = useDb();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleText = (e) => {
    const text = snakeCaseText(e.target.value);
    setData({ ...data, [e.target.name]: text });
  };

  const handleClose = () => {
    setData({});
    setLoading(false);
    close();
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const modelId = await addStandardDoc("models", {
        ...data,
        modelType,
        baseModel: baseModelDefaults[modelType],
      });
      history.push(`/${modelType}/${modelId}`);
      handleClose();
    } catch (err) {
      window.alert(err.message);
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Name your Model</DialogTitle>
      <form onSubmit={handleCreate}>
        <DialogContent>
          <TextField
            {...textFieldProps}
            onChange={handleText}
            name="name"
            label="Model Name"
            value={data.name || ""}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading || !data.name}
            endIcon={<ArrowForward />}
          >
            Next
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
