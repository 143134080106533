import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import FileUploader from "../../shared/FileUploader";
import { useDb } from "../../../contexts/DatabaseContext";
import LinksTable from "./LinksTable";
import { GiVintageRobot } from "react-icons/gi";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function AddLinks() {
  const { siteId } = useParams();
  const history = useHistory();
  const { parseCSV, createLinkDocuments } = useDb();
  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState();

  const handleCSV = async (file) => {
    const result = await parseCSV(file.file);
    setLinks(result);
  };

  const beginAnalysis = async () => {
    setLoading(true);
    try {
      await createLinkDocuments(links, siteId);
      history.push(`/site/${siteId}`);
      setLoading(false);
    } catch (err) {
      window.alert(err.message);
      setLoading(false);
    }
  };

  return (
    <div>
      <Typography variant="h4">Add Links CSV from Sitemap</Typography>
      <Alert severity="info" style={{ marginBottom: 10 }}>
        In most cases, you can find your website's sitemap by adding
        "/sitemap.xml" to the end of your website's URL. Then use{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.seowl.co/sitemap-extractor/"
          style={{ textDecoration: "underline", color: "#1876d2" }}
        >
          <b>this tool</b>
        </a>{" "}
        to get all your sites links.
      </Alert>
      <Card>
        <CardContent>
          <FileUploader sendFile={handleCSV} />
        </CardContent>
      </Card>
      <br />
      {links && (
        <Card>
          <CardHeader
            title={
              <Typography>
                Found <b>{links.length}</b> links:
              </Typography>
            }
            subheader={
              loading
                ? "Loading, please be patient as this can take ~1 minute ... "
                : "."
            }
            action={
              <Button
                startIcon={<GiVintageRobot />}
                variant="contained"
                color="primary"
                onClick={beginAnalysis}
                disabled={loading}
              >
                Begin Analysis
              </Button>
            }
          />
          <CardContent>
            <LinksTable links={links} />
          </CardContent>
        </Card>
      )}
    </div>
  );
}
