import { Box, Container } from "@material-ui/core";
import React from "react";
import BackLink from "../../../shared/BackLink";
import { useParams } from "react-router-dom";
import { Card, CardContent, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import BuyerPersonas from "../buyer-personas/BuyerPersonas";
import Firmographics from "./firmographics/Firmographics";
import JobTitles from "./jobtitles/JobTitles";
import CombinedBuyerPersonas from "./personas/CombinedBuyerPersonas";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Targeting() {
  const { siteId } = useParams();
  const [tabValue, setTabValue] = React.useState(0);

  const tabs = [
    {
      label: "Buyer Personas",
      component: <CombinedBuyerPersonas siteId={siteId} />,
    },
    { label: "Firmographics", component: <Firmographics siteId={siteId} /> },
    {
      label: "Persona Attributes",
      component: <BuyerPersonas siteId={siteId} />,
    },
    { label: "Job Titles", component: <JobTitles siteId={siteId} /> },
  ];

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Container>
        <BackLink override={`/site/${siteId}`} label="Site Overview" />
        <Typography variant="h4">Targeting</Typography>
        <Card>
          <CardContent>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                {tabs.map((tab, index) => (
                  <Tab label={tab.label} {...a11yProps(index)} key={index} />
                ))}
              </Tabs>
            </Box>
            {tabs.map((tab, index) => (
              <TabPanel value={tabValue} index={index} key={index}>
                {tab.component}
              </TabPanel>
            ))}
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
