import { Business, Edit, PeopleRounded } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import EditDemographic from "./EditDemographic";
import { useDb } from "../../../../../contexts/DatabaseContext";

export default function Demographics({ siteId }) {
  const { GetCollectionFilter } = useDb();
  const demographics = GetCollectionFilter("demographics", "siteId", siteId);
  console.log(demographics);

  const [selectedDemo, setSelectedDemo] = useState();
  const [editOpen, setEditOpen] = useState(false);

  const handleEdit = (demoId) => {
    setSelectedDemo(demoId);
    setEditOpen(true);
  };

  const handleClose = () => {
    setEditOpen(false);
    setSelectedDemo(null);
  };

  return (
    <Card>
      <CardHeader title="Demographics" avatar={<PeopleRounded />} />
      <Alert severity="info">
        Please list the job titles normally associated with each industry you
        selected for the following personas. If there are no differences in job
        titles across all industries select, we target the same job titles for
        all industries
      </Alert>
      <CardContent>
        {demographics &&
          demographics.map((dem) => (
            <Card key={dem.id} className="company-checklist-item">
              <CardHeader
                title={dem.industry}
                avatar={
                  <Avatar>
                    <Business />
                  </Avatar>
                }
                action={
                  <IconButton onClick={() => handleEdit(dem.id)}>
                    <Edit />
                  </IconButton>
                }
              />
            </Card>
          ))}
      </CardContent>
      {/* <CardActions>
        <Button>Custom Industry Segment</Button>
      </CardActions> */}

      {selectedDemo && (
        <EditDemographic
          open={editOpen}
          close={handleClose}
          demoId={selectedDemo}
        />
      )}
    </Card>
  );
}
