import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import BackLink from "../../../../shared/BackLink";
import { FcFilingCabinet } from "react-icons/fc";
import { Add } from "@mui/icons-material";
import { useDb } from "../../../../../contexts/DatabaseContext";
import CreateSelectable from "../utilities/CreateSelectable";
import SelectableCard from "../utilities/SelectableCard";
import ModelSettings from "../utilities/ModelSettings";
import LinkClassificationPreview from "./LinkClassificationPreview";

export default function LinkClassificationCreator() {
  const { modelId } = useParams();
  const { getStandardDoc, GetOrgCollectionWithFilter } = useDb();
  const [catOpen, setCatOpen] = useState(false);
  const [model, setModel] = useState();
  const selections = GetOrgCollectionWithFilter("selections", "modelId", modelId);
  const [updated, setUpdated] = useState(new Date());

  const toggleCatOpen = () => {
    setCatOpen(!catOpen);
  };

  const retrieveModel = async () => {
    const m = await getStandardDoc("models", modelId);
    setModel(m);
  };

  useEffect(() => {
    const unsubscribe = retrieveModel();
    return unsubscribe;
  }, [modelId]);

  return !model ? (
    <LinearProgress />
  ) : (
    <div>
      <Container>
        <h2 className="model-header">{model.name}</h2>
        <Typography>Text Classification Model</Typography>
        <BackLink />
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Card>
                <CardHeader
                  title="Categories"
                  subheader="Add categories for your model to classify text into."
                  avatar={<FcFilingCabinet />}
                  action={
                    <Button
                      onClick={toggleCatOpen}
                      color="primary"
                      startIcon={<Add />}
                    >
                      Add Category
                    </Button>
                  }
                />
                <CardContent>
                  {selections &&
                    selections.map((sel) => (
                      <SelectableCard key={sel.id} item={sel} />
                    ))}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <ModelSettings model={model} />
            </Grid>
          </Grid>
          <LinkClassificationPreview model={model} selections={selections} />
        </div>
      </Container>

      <CreateSelectable
        open={catOpen}
        close={() => setCatOpen(false)}
        model={model}
      />
    </div>
  );
}
