import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { useDb } from "../../../../contexts/DatabaseContext";
import ChatBox from "./messages/ChatBox";
import MsgTemplate from "./messages/MsgTemplate";

export default function ContentMessages({ generateId }) {
  const { GetOrgCollectionWithFilter } = useDb();
  const [snackOpen, setSnackOpen] = React.useState(false);
  const messages = GetOrgCollectionWithFilter(
    "messages",
    "generateId",
    generateId
  );

  if (!messages || messages.length < 1) {
    return <Alert severity="info">Preparing your content ...</Alert>;
  }

  return (
    <div>
      {messages &&
        messages.map((msg) => (
          <MsgTemplate
            msg={msg}
            key={msg.id}
            copied={() => setSnackOpen(true)}
          />
        ))}
      {/* <div className="sticky-chatbox">
        <ChatBox />
      </div> */}

      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        onClose={() => setSnackOpen(false)}
        message="Text copied to clipboard!"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        color="success"
      />
    </div>
  );
}
