import React from "react";
import { Redirect, Route } from "react-router-dom";
import Account from "../components/customer/account/Account";
import PaymentFailed from "../components/customer/account/PaymentFailed";
import SelectPlan from "../components/customer/account/SelectPlan";
import VerifyPayment from "../components/customer/account/VerifyPayment";
import GenerateContent from "../components/customer/generate/GenerateContent";
import AddLinks from "../components/customer/links/AddLinks";
import ReviewLinks from "../components/customer/models/review/ReviewLinks";
import ReviewQueue from "../components/customer/models/review/ReviewQueue";
import SiteDetail from "../components/customer/site-detail/SiteDetail";
import Sites from "../components/customer/site-detail/Sites";
import IwanTests from "../components/customer/tests/IwanTests";
import CreateModel from "../components/customer/models/create_model/CreateModel";
import LinkClassificationCreator from "../components/customer/models/create_model/link-classification/LinkClassificationCreator";
import TextClassificationCreator from "../components/customer/models/create_model/text-classification/TextClassificationCreator";
import TextClassificationView from "../components/customer/models/create_model/text-classification/TextClassificationView";
import ModelDetail from "../components/customer/models/ModelDetail";
import Models from "../components/customer/models/Models";
import SiteReviewQueue from "../components/customer/models/review/SiteReviewQueue";
import Team from "../components/customer/team/Team";
import CompanyInfo from "../components/customer/site-detail/site-setup/CompanyInfo";
import BuyerPersonaInfo from "../components/customer/site-detail/site-setup/BuyerPersonaInfo";
import CompetitorInfo from "../components/customer/site-detail/site-setup/CompetitorInfo";
import Integrations from "../components/customer/integrations/Integrations";
import ContentCreator from "../components/customer/site-detail/content-generation/manage-content/ContentCreator";
import TopicGenerateContainer from "../components/customer/site-detail/content-generation/topics/TopicGenerateContainer";
import PublishContent from "../components/customer/site-detail/content-generation/publishing/PublishContent";
import ScheduleContent from "../components/customer/site-detail/content-generation/publishing/ScheduleContent";
import ContentManager from "../components/customer/site-detail/content-generation/manage-content/ContentManager";
import Targeting from "../components/customer/site-detail/targeting/Targeting";
import FirmographicsInfo from "../components/customer/site-detail/targeting/firmographics/FirmographicsInfo";
import JobTitlesInfo from "../components/customer/site-detail/targeting/jobtitles/JobTitlesInfo";

export default function BuyerRoutes() {
  return (
    <>
      <Route exact path="/" component={Sites} />
      <Route path="/site/:siteId" component={SiteDetail} />
      <Route exact path="/add-links/:siteId" component={AddLinks} />
      <Route exact path="/generate/:siteId" component={GenerateContent} />
      <Route exact path="/account" component={Account} />
      <Route exact path="/select-plan" component={SelectPlan} />
      <Route exact path="/verify-subscription" component={VerifyPayment} />
      <Route exact path="/payment-failed" component={PaymentFailed} />
      <Route exact path="/review/:modelId/:siteId" component={ReviewLinks} />
      <Route exact path="/review-queue/:modelId" component={ReviewQueue} />
      <Route
        exact
        path="/site-review-queue/:modelId/:siteId"
        component={SiteReviewQueue}
      />
      <Route exact path="/team" component={Team} />
      <Route exact path="/tests" component={IwanTests} />

      {/* Importing FTG */}
      <Route exact path="/models" component={Models} />
      <Route exact path="/create-model" component={CreateModel} />
      <Route exact path="/model/:modelId" component={ModelDetail} />
      <Route
        exact
        path="/text-classification/:modelId"
        component={TextClassificationCreator}
      />
      <Route
        exact
        path="/view/text-classification/:modelId"
        component={TextClassificationView}
      />
      <Route
        exact
        path="/link-classification/:modelId"
        component={LinkClassificationCreator}
      />

      {/* New Site Set Up Stuff */}
      <Route exact path="/company-info/:siteId" component={CompanyInfo} />
      <Route
        exact
        path="/buyer-persona-info/:siteId/:personaId"
        component={BuyerPersonaInfo}
      />
      <Route exact path="/competitor-info/:siteId" component={CompetitorInfo} />
      <Route exact path="/integrations" component={Integrations} />

      {/* New Content Generations Stuff */}
      <Route
        exact
        path="/generate-content/:siteId"
        component={TopicGenerateContainer}
      />
      <Route
        exact
        path="/content-creator/:siteId/:generateId"
        component={ContentCreator}
      />
      <Route
        exact
        path="/publish-content/:siteId/:generateId"
        component={PublishContent}
      />
      <Route
        exact
        path="/schedule-content/:siteId/:generateId"
        component={ScheduleContent}
      />
      <Route exact path="/content-manager/:siteId" component={ContentManager} />
      <Route exact path="/targeting/:siteId" component={Targeting} />

      <Route
        exact
        path="/firmographics-info/:siteId/:firmographicsId"
        component={FirmographicsInfo}
      />
      <Route
        exact
        path="/jobtitles-info/:siteId/:jobtitleId"
        component={JobTitlesInfo}
      />

      {/* TODO: Catch Unrouted Routes */}
      {/* <Route exact path="*">
        <Redirect to="/" />
      </Route> */}
    </>
  );
}
