import { Button, LinearProgress } from "@mui/material";
import React, { useState } from "react";
import ContentMessages from "./ContentMessages";
import { functions } from "../../../../firebase";
import { AutoAwesome } from "@mui/icons-material";

export default function GenerateContentStep({
  generateId,
  siteId,
  wordCount,
  generation,
  setGeneration,
  site,
  tone,
  prompts,
}) {
  const [loading, setLoading] = useState(false);

  const handleGenerate = async () => {
    const generateContent = functions.httpsCallable("generateContent");
    setGeneration({
      ...generation,
      started: true,
    });
    setLoading(true);
    await generateContent({
      wordCount,
      topic: generation.topic,
      siteId,
      generateId,
      tone,
      systemPromptText: prompts.system,
      userPromptText: prompts.user,
    });
    setLoading(false);
  };

  return !generation || !site ? (
    <LinearProgress />
  ) : (
    <div>
      {!generation.started && (
        <Button
          disabled={loading}
          variant="contained"
          onClick={handleGenerate}
          size="large"
          startIcon={<AutoAwesome />}
        >
          Generate Content
        </Button>
      )}
      {generation.started && <ContentMessages generateId={generateId} />}
    </div>
  );
}
