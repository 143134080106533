import {
  AppBar,
  Avatar,
  Box,
  CssBaseline,
  IconButton,
  MenuItem,
  Toolbar,
  Tooltip,
  Menu,
  Typography,
  Badge,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import logo from "../../img/long.png";
import { useAuth } from "../../contexts/AuthContext";
import CustomerRoutes from "../../routes/CustomerRoutes";
import { Link, useHistory } from "react-router-dom";
import { Notifications } from "@mui/icons-material";
import CustomerHorizontalMenu from "./CustomerHorizontalMenu";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    background: "#fff",
    borderBottom: "4px #57bdc8 solid",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  icons: {
    color: "#fff",
    marginLeft: theme.spacing(2),
  },
  menuText: {
    color: theme.palette.mainMenuText,
    textDecoration: "none",
  },
  subMenuItem: {
    paddingLeft: 55,
  },
}));

export default function CustomerPage() {
  const { currentUser, logOut } = useAuth();
  const classes = useStyles();

  const [anchorElUser, setAnchorElUser] = useState(null);
  const history = useHistory();
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar elevation={0} position="fixed" className={classes.appBar}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Link to="/">
              <img src={logo} height={40} style={{ top: 10, left: 25 }} />
            </Link>
            <CustomerHorizontalMenu />
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            ></Typography>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {/* TODO: Bring back this notifications button once you you have, you know, notifications to show */}
            {/* <IconButton size="large">
              <Badge badgeContent={4} color="primary">
                <Notifications />
              </Badge>
            </IconButton> */}
            &nbsp;
            <Tooltip title="User Account">
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ p: 0 }}
                size="large"
              >
                {currentUser && currentUser.profile_photo ? (
                  <Avatar
                    style={{ background: "#47b9e3" }}
                    src={currentUser.profile_photo}
                  />
                ) : (
                  <Avatar>{currentUser.firstName[0] || "C"}</Avatar>
                )}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={() => history.push("/account")}>
                <Typography>Your Account</Typography>
              </MenuItem>
              <MenuItem onClick={logOut}>
                <Typography>Log Out</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <CustomerRoutes />
      </main>
    </div>
  );
}
