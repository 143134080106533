import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { storage } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";

import { CloudUpload } from "@mui/icons-material";
import { Alert } from "@mui/material";

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function FileUploader({ sendFile, label, storagePath }) {
  const { currentUser } = useAuth();
  const storageRef = storagePath
    ? storage.ref().child(storagePath)
    : storage.ref("users").child(currentUser.uid);
  const [fileSelected, setFileSelected] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setFileSelected(true);
    }
  };

  const handleUploadStart = () => {
    setIsUploading(true);
    setProgress(0);
    console.log(file);
    const fileName = Math.random().toString(36).slice(-10);
    const uploadTask = storageRef
      .child(`documents/${fileName}/${file.name}`)
      .put(file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var upProgress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(upProgress);
      },
      (error) => {
        setError("Error during File Upload");
        setIsUploading(false);
        setFileSelected(false);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          setProgress(100);
          setIsUploading(false);
          setFileSelected(false);
          sendFile({
            url,
            fileName: file.name,
            file,
          });
        });
      }
    );
  };

  return (
    <Box style={{ width: "100%", marginTop: "1rem" }}>
      <Card className="company-checklist-item">
        <CardContent>
          {label || "Upload File"}
          <br />
          <br />
          <input
            type="file"
            onChange={handleFileChange}
            style={{ marginBottom: "1rem" }}
          />
          <br />
          <LinearProgressWithLabel value={progress} />
          <br />
          {error && (
            <Alert
              style={{ width: "100%", marginBottom: "1rem" }}
              severity="error"
            >
              Error Uploading file
            </Alert>
          )}
          <Button
            disableElevation
            disabled={!fileSelected || isUploading}
            onClick={handleUploadStart}
            startIcon={<CloudUpload />}
            variant="contained"
            color="primary"
          >
            Upload
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
}
