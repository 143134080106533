import React, { useState } from "react";
import { useDb } from "../../../../../../contexts/DatabaseContext";
import { Avatar, Card, CardHeader, Grid, LinearProgress } from "@mui/material";
import LinksStatus from "./LinksStatus";
import { WebRounded } from "@mui/icons-material";
import SitePersonaChart from "./SitePersonaChart";
import SiteBuyerStageChart from "./SiteBuyerStageChart";
import CombinedChart from "./CombinedChart";

export default function SiteContent({ siteId }) {
  const { GetLinksForSite, getStandardDoc } = useDb();
  const links = GetLinksForSite(siteId);

  const [site, setSite] = useState();
  // const [selections, setSelections] = useState([]);

  const loadSite = async () => {
    const s = await getStandardDoc("sites", siteId);
    setSite(s);
  };

  if (!site) {
    loadSite();
    return <LinearProgress />;
  }

  console.log(links);

  return (
    <div>
      <Card>
        <CardHeader
          avatar={
            <Avatar>
              <WebRounded />
            </Avatar>
          }
          title="Content Overview"
        />
        {links && site.analyzer_status === "Complete" && (
          // <Grid container spacing={3}>
          //   <Grid item xs={12} md={6}>
          //     <SitePersonaChart links={links} />
          //   </Grid>
          //   <Grid item xs={12} md={6}>
          //     <SiteBuyerStageChart links={links} />
          //   </Grid>
          // </Grid>
          <CombinedChart links={links} />
        )}
        <LinksStatus links={links} />
      </Card>
    </div>
  );
}
