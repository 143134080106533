import {
  Avatar,
  Card,
  CardActionArea,
  CardHeader,
  CardMedia,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MoreVert } from "@mui/icons-material";
import React from "react";
import defaultScreenshot from "../../../img/default_screenshot.png";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 250,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function SiteCard({ site }) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Card>
      <CardActionArea onClick={() => history.push(`/site/${site.id}`)}>
        <CardMedia
          className={classes.media}
          image={site.screenshot || defaultScreenshot}
          title="Contemplative Reptile"
        />

        <CardHeader
          title={site.name}
          subheader={site.url}
          subheaderTypographyProps={{ noWrap: true }}
          avatar={<Avatar className={classes.avatar}>{site.name[0]}</Avatar>}
          action={
            <IconButton size="large">
              <MoreVert />
            </IconButton>
          }
        />
      </CardActionArea>
    </Card>
  );
}
