import { Card, CardHeader, Grid } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Firmographics from "./Firmographics";
import Demographics from "./Demographics";

export default function Targeting() {
  const { siteId } = useParams();
  return (
    <>
      <Card className="site-detail-header">
        <CardHeader
          sx={{ background: "none" }}
          title="Targeting"
          subheader="Here you target firmographics and demographics"
        />
      </Card>
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <Demographics siteId={siteId} />
        </Grid>
        <Grid item xs={12} md={5}>
          <Firmographics siteId={siteId} />
        </Grid>
      </Grid>
    </>
  );
}
