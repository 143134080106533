import {
  Add,
  BusinessRounded,
  Delete,
  EditRounded,
  TravelExploreRounded,
} from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
} from "@mui/material";
import React, { useState } from "react";
import AddCompetitor from "./AddCompetitor";
import { useDb } from "../../../../../contexts/DatabaseContext";

export default function Competitors({ siteId }) {
  const [addOpen, setAddOpen] = useState(false);
  const { GetOrgCollectionWithFilter, deleteStandardDoc } = useDb();
  const competitors = GetOrgCollectionWithFilter(
    "competitors",
    "siteId",
    siteId
  );

  const handleDelete = async (compId) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this competitor?"
    );
    if (confirm) {
      await deleteStandardDoc("competitors", compId);
    }
  };

  return (
    <Card>
      <CardHeader
        title="Competitors"
        subheader="Add competitors here and we will automatically conduct an anlysis of their online presence"
        avatar={<TravelExploreRounded />}
      />
      <CardActions>
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={() => setAddOpen(true)}
        >
          Add
        </Button>
      </CardActions>
      <CardContent>
        <List>
          {competitors &&
            competitors.map((comp) => {
              return (
                <ListItem divider key={comp.id}>
                  <Card className="company-checklist-item">
                    <CardHeader
                      title={comp.name}
                      avatar={<BusinessRounded />}
                      subheader={comp.url}
                      action={
                        <IconButton onClick={() => handleDelete(comp.id)}>
                          <Delete />
                        </IconButton>
                      }
                    />
                  </Card>
                </ListItem>
              );
            })}
        </List>
      </CardContent>

      <AddCompetitor
        open={addOpen}
        close={() => setAddOpen(false)}
        siteId={siteId}
      />
    </Card>
  );
}
