import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import { textFieldProps } from "../../../../shared/constants";
import { useDb } from "../../../../../contexts/DatabaseContext";

export default function CaptureCompanyInfoText({
  open,
  close,
  siteId,
  field,
  question,
  subheader,
  confirmCheck,
}) {
  const { updateStandardDoc } = useDb();
  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setAnswer("");
    close();
  };

  const handleSave = async () => {
    setLoading(true);
    const fieldDot = `setup_steps.${[field]}`;
    await updateStandardDoc("sites", siteId, {
      [field]: answer,
      [fieldDot]: true,
    });
    window.location.reload();
    confirmCheck();
    setLoading(false);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{question}</DialogTitle>
      <DialogContent>
        <TextField
          {...textFieldProps}
          label={subheader}
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          multiline
          rows={4}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} color="error" onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleSave} disabled={loading} variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
