import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useState } from "react";
import AddCombinedBuyerPersona from "./AddCombinedBuyerPersona";
import InfoTable from "../InfoTable";

export default function CombinedBuyerPersonas({ siteId }) {
  const [addOpen, setAddOpen] = useState(false);

  const additionalColumns = [
    {
      field: "firmographics",
      headerName: "Firmographics",
      width: 250,
      valueGetter: (params) => {
        return params.row.firmographics?.name || "";
      },
    },
    {
      field: "jobTitles",
      headerName: "Job Titles",
      width: 250,
      valueGetter: (params) => {
        return params.row.jobTitles?.name || "";
      },
    },
    {
      field: "personaAttributes",
      headerName: "Persona Attributes",
      width: 250,
      valueGetter: (params) => {
        return params.row.personaAttributes?.name || "";
      },
    },
  ];
  return (
    <div>
      <Button onClick={() => setAddOpen(true)} startIcon={<Add />}>
        Add Buyer Persona
      </Button>

      <InfoTable
        siteId={siteId}
        infoTypeCollection="combinedPersonas"
        additionalColumns={additionalColumns}
      />

      <AddCombinedBuyerPersona
        open={addOpen}
        close={() => setAddOpen(false)}
        siteId={siteId}
      />
    </div>
  );
}
