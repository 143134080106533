import { EventRepeat, Send } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState } from "react";
import { GrWordpress } from "react-icons/gr";
import BackLink from "../../../../shared/BackLink";

export default function PublishContent() {
  const [wpChecked, setWpChecked] = useState(false);
  const [scheduleChecked, setScheduleChecked] = useState(false);
  return (
    <div>
      <Container>
        <BackLink />
        <Card elevation={0}>
          <CardHeader
            title="Publish Content"
            subheader="Publish your content to your site, and create content creation schedule."
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Card>
                  <CardHeader
                    title="Create WordPress Post"
                    subheader="Publish your content to your WordPress site, will create a Post as Draft or Published based on your settings."
                    avatar={
                      <Avatar>
                        <GrWordpress />
                      </Avatar>
                    }
                    action={
                      <Checkbox
                        checked={wpChecked}
                        onChange={(e) => setWpChecked(e.target.checked)}
                        color="primary"
                      />
                    }
                  />
                </Card>
              </Grid>
            </Grid>
            <br />
            <Card>
              <CardHeader
                title="Schedule Recurring Content Creation"
                avatar={
                  <Avatar>
                    <EventRepeat />
                  </Avatar>
                }
                subheader="Save all the steps from this content creation process, and schedule it to run again in the future on a repeating schedule. Our AI Assistant will create new, unique content every time for you, and publish it to your site."
              />
              <CardContent>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={scheduleChecked}
                      onChange={(e) => setScheduleChecked(e.target.checked)}
                    />
                  }
                  label="Create Schedule with these settings."
                />
                <br />
                {scheduleChecked && (
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      Publishing Schedule
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="weekly"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="weekly"
                        control={<Radio />}
                        label="Weekly"
                      />
                      <FormControlLabel
                        value="monthly"
                        control={<Radio />}
                        label="Monthly"
                      />
                      <FormControlLabel
                        value="quarterly"
                        control={<Radio />}
                        label="Quarterly"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              </CardContent>
            </Card>
          </CardContent>
        </Card>
        <br />
        <Box textAlign="center">
          <Button
            disabled={!wpChecked}
            size="large"
            variant="contained"
            endIcon={<Send />}
          >
            Publish Content
          </Button>
        </Box>
      </Container>
    </div>
  );
}
