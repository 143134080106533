import React from "react";
import { useParams, Link } from "react-router-dom/cjs/react-router-dom.min";
import ContentGeneration from "../../content-generation/ContentGeneration";
import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import { RestoreRounded } from "@mui/icons-material";
import RMFDataGrid from "../../../../shared/data-grid/RMFDataGrid";
import { useDb } from "../../../../../contexts/DatabaseContext";

export default function ContentCreation() {
  const { siteId } = useParams();
  const { GetCollectionFilter } = useDb();
  const generations = GetCollectionFilter("generate", "siteId", siteId);

  console.log(generations);

  const rows =
    generations &&
    generations.map((gen) => ({
      ...gen,
    }));

  const columns = [
    {
      field: "topic",
      headerName: "Topic",
      width: 800,
      renderCell: (params) => {
        return (
          <Link to={`/content-creator/${siteId}/${params.row.id}`}>
            <Typography fontWeight={"bold"} color="primary">
              {params.row.topic}
            </Typography>
          </Link>
        );
      },
    },
  ];

  return (
    <Stack spacing={2}>
      <Card className="site-detail-header">
        <CardHeader
          title="Content Creation"
          subheader="Create Content based on your existing content..."
        />
      </Card>
      <ContentGeneration siteId={siteId} />
      <Card>
        <CardHeader
          title="Generated Content"
          subheader="Your previously generated content"
          avatar={<RestoreRounded />}
        />
        <CardContent>
          {rows && <RMFDataGrid data={rows} columns={columns} />}
        </CardContent>
      </Card>
    </Stack>
  );
}
