import { Warning } from "@mui/icons-material";
import { Button, Card, CardHeader } from "@mui/material";
import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDb } from "../../../../../contexts/DatabaseContext";

export default function SiteSettings() {
  const { siteId } = useParams();
  const history = useHistory();
  const { deleteStandardDoc } = useDb();

  const handleSiteDelete = async () => {
    const confirm = window.confirm(
      "Are you sure you want to delete this site? This action can not be undone"
    );
    if (confirm) {
      await deleteStandardDoc("sites", siteId);
      history.push("/");
    }
  };

  return (
    <div>
      <Card className="site-detail-header">
        <CardHeader title="Site Settings" />
      </Card>

      <Button
        color="error"
        variant="contained"
        startIcon={<Warning />}
        onClick={handleSiteDelete}
      >
        Delete Site
      </Button>
    </div>
  );
}
