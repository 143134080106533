import React from "react";
import RMFDataGrid from "../../shared/data-grid/RMFDataGrid";

export default function LinksTable({ links }) {
  const rows =
    links &&
    links.map((link) => ({
      id: link,
      link,
    }));

  const columns = [
    {
      field: "url",
      headerName: "URL",
      width: 700,
      renderCell: (params) => {
        return (
          <a
            style={{ textDecoration: "underline", color: "#1876d2" }}
            target="_blank"
            rel="noopener noreferrer"
            href={params.row.link}
          >
            {params.row.link}
          </a>
        );
      },
    },
  ];

  return (
    <div>
      <RMFDataGrid data={rows} columns={columns} />
    </div>
  );
}
