import { Container } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import BackLink from "../../../shared/BackLink";
import InfoWizard from "../targeting/info-wizard/InfoWizard";

const steps = [
  {
    label: "Competitor Names",
    description:
      "What are the names of your top 3-5 competitors? One per line.",
    name: "competitorName",
  },
  {
    label: "Competitor URLs",
    description: "Please paste their website URLs. One per line.",
    name: "competitorUrls",
  },
  {
    label: "Differentiators",
    description:
      "What are the key differentiators between your product/service and theirs?",
    name: "differentiators",
  },
];

export default function CompetitorInfo() {
  const { siteId } = useParams();

  return (
    <div>
      <Container>
        <BackLink override={`/site/${siteId}/`} />
        <InfoWizard
          steps={steps}
          siteId={siteId}
          title="Competitor Information"
          category={"competitorInfo"}
        />
      </Container>
    </div>
  );
}
