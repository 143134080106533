import React from "react";
import Lottie from "lottie-react";
import { Alert, Container, Grid } from "@mui/material";

export default function AnimationContainer({ message, animationData }) {
  return (
    <Container>
      <Grid container spacing={0}>
        <Grid item xs={12} md={3}></Grid>
        <Grid item xs={12} md={6}>
          <div style={{ textAlign: "center" }}>
            <Lottie animationData={animationData} loop={true} />
            <Alert severity="info">{message || "Loading..."}</Alert>
          </div>
        </Grid>
        <Grid item xs={12} md={3}></Grid>
      </Grid>
    </Container>
  );
}
