import { ButtonGroup, Card, CardHeader, IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import React from "react";
import { FcDocument } from "react-icons/fc";

export default function SelectableCard({ item, handleDelete }) {
  return (
    <Card style={{ marginBottom: 10 }}>
      <CardHeader
        title={item.name}
        subheader={
          item.options
            ? item.options.map((option) => option.label).join(", ")
            : ""
        }
        subheaderTypographyProps={{
          noWrap: true, // Prevents text from wrapping to the next line
          style: {
            overflow: "hidden", // Hides any overflowed text
            textOverflow: "ellipsis", // Adds an ellipsis to indicate truncated text
            maxWidth: "300px", // Prevents text from overflowing the container
          },
        }}
        avatar={<FcDocument />}
        action={
          <ButtonGroup>
            <IconButton size="large">
              <Edit />
            </IconButton>
            <IconButton onClick={() => handleDelete(item)} size="large">
              <Delete />
            </IconButton>
          </ButtonGroup>
        }
      />
    </Card>
  );
}
