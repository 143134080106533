import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import FileUploader from "../../../../shared/FileUploader";
import { textFieldProps } from "../../../../shared/constants";
import { useDb } from "../../../../../contexts/DatabaseContext";
import { Save } from "@mui/icons-material";

export default function UploadMediaLibrary({ open, close, siteId }) {
  const { addStandardDoc } = useDb();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState();
  const [fileUrl, setFileUrl] = useState();
  const [fileName, setFileName] = useState();

  const handleClose = () => {
    setLoading(false);
    close();
  };

  const getFile = (file) => {
    setFileName(file.fileName);
    setFileUrl(file.url);
  };

  const handleSave = async () => {
    setLoading(true);
    await addStandardDoc("media_library", {
      siteId,
      title,
      fileUrl,
      fileName,
    });
    setLoading(false);
    handleClose();
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Upload to Media Library</DialogTitle>
      <DialogContent>
        <TextField
          {...textFieldProps}
          label="Document Name"
          onChange={(e) => setTitle(e.target.value)}
        />
        <FileUploader sendFile={getFile} label="Upload Document" />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} color="error" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={loading || !title || !fileName || !fileUrl}
          variant="contained"
          startIcon={<Save />}
          onClick={handleSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
