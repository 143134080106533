import { Card, CardHeader, Grid, Stack } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CompanyChecklist from "./CompanyChecklist";
import Competitors from "./Competitors";

export default function CompanyInfo() {
  const { siteId } = useParams();
  return (
    <Stack spacing={2}>
      <Card className="site-detail-header">
        <CardHeader title="Your Company Info" />
      </Card>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <CompanyChecklist siteId={siteId} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Competitors siteId={siteId} />
        </Grid>
      </Grid>
    </Stack>
  );
}
