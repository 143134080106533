import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Container,
} from "@mui/material";
import React, { useState } from "react";
import { GiRocketThruster } from "react-icons/gi";
import BackLink from "../../shared/BackLink";
import { FaRocket } from "react-icons/fa";
import { useDb } from "../../../contexts/DatabaseContext";

export default function SelectPlan() {
  const { createSubscriptionCheckoutSession } = useDb();
  const [loading, setLoading] = useState(false);

  const toggleLoading = () => {
    setLoading(!loading);
  };

  const handlePlanSelect = async (plan) => {
    toggleLoading();
    try {
      await createSubscriptionCheckoutSession(plan);
    } catch (err) {
      window.alert(err.message);
      toggleLoading();
    }
    toggleLoading();
  };

  return (
    <div>
      <Container>
        <Typography variant="h4">Select Plan</Typography>
        <BackLink />
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Card>
                  <CardHeader
                    title="Monthly Subscription"
                    avatar={
                      <Avatar style={{ background: "#055cb6" }}>
                        <FaRocket />
                      </Avatar>
                    }
                  />
                  <CardContent style={{ textAlign: "center" }}>
                    <Typography variant="h6" color="primary">
                      $59
                    </Typography>
                    <Typography>per month</Typography>
                    <br />
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={loading}
                      onClick={() =>
                        handlePlanSelect("price_1Mdv9MFzBGVITxEKrDiYbA4J")
                      }
                    >
                      {loading ? "Loading, please wait" : "Select Subscription"}
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card>
                  <CardHeader
                    title="Annual Subscription"
                    avatar={
                      <Avatar style={{ background: "#055cb6" }}>
                        <GiRocketThruster />
                      </Avatar>
                    }
                  />
                  <CardContent style={{ textAlign: "center" }}>
                    <Typography variant="h6" color="primary">
                      $599
                    </Typography>
                    <Typography>per year</Typography>
                    <br />
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={loading}
                      onClick={() =>
                        handlePlanSelect("price_1MdvAIFzBGVITxEKTv9z3U7F")
                      }
                    >
                      {loading ? "Loading, please wait" : "Select Subscription"}
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
