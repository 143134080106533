import { Info } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  LinearProgress,
  Snackbar,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDb } from "../../../../../contexts/DatabaseContext";
import { textFieldProps } from "../../../../shared/constants";
import CompleteDialog from "../../site-setup/CompleteDialog";
import { fieldTypes } from "./fieldTypes";

const nextLinks = {
  companyInfo: "/buyer-persona-info",
  buyerPersonaInfo: "/competitor-info",
};

export default function InfoWizard({
  steps,
  category,
  siteId,
  title,
  collection,
  documentId,
}) {
  const { updateStandardDoc, getStandardDoc } = useDb();
  const [activeStep, setActiveStep] = useState();
  const [responses, setResponses] = useState({});
  const [loading, setLoading] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [completeOpen, setCompleteOpen] = useState(false);

  const loadSite = async () => {
    const siteDoc = await getStandardDoc(
      collection || "sites",
      documentId || siteId
    );
    const savedResponses = siteDoc[`${category}Responses`];
    // Filter out the objects from the `steps` array that match the keys in `firestoreObject`
    if (savedResponses) {
      const filteredSteps = steps.filter((step) =>
        Object.keys(savedResponses).includes(step.name)
      );
      // Use `reduce()` to create a new object with only the desired keys and values from `firestoreObject`
      const filteredFirestoreObject = filteredSteps.reduce((acc, step) => {
        acc[step.name] = savedResponses[step.name];
        return acc;
      }, {});

      setResponses(filteredFirestoreObject);
      if (siteDoc[`${category}Complete`]) {
        setActiveStep(siteDoc[`${category}Step`] - 1);
      } else setActiveStep(siteDoc[`${category}Step`] || 0);
      setPageLoaded(true);
    } else {
      console.log("No responses found");
      setActiveStep(0);
      setPageLoaded(true);
    }
  };

  useEffect(() => {
    const unsubscribe = loadSite();
    return unsubscribe;
  }, [siteId]);

  const handleUpdate = async () => {
    setLoading(true);
    try {
      await updateStandardDoc(collection || "sites", documentId || siteId, {
        [`${category}Responses`]: responses,
        [`${category}Step`]: activeStep + 1,
      });
      setSnackOpen(true);
    } catch (err) {
      window.alert(err.message);
    }
    setLoading(false);
  };

  const handleComplete = async () => {
    setLoading(true);
    try {
      await updateStandardDoc(collection || "sites", documentId || siteId, {
        [`${category}Complete`]: true,
      });
      setSnackOpen(true);
    } catch (err) {
      window.alert(err.message);
    }
    setLoading(false);
  };

  const handleText = (e) => {
    setResponses({ ...responses, [e.target.name]: e.target.value });
  };

  const handleNext = async () => {
    await handleUpdate();
    if (steps.length === activeStep + 1) {
      await handleComplete();
      setCompleteOpen(true);
    } else setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleInputChange = (name, value, type) => {
    console.log(name, value, type);
    setResponses({ ...responses, [name]: value });
  };

  const renderField = (field) => {
    const fieldProps = {
      ...field.props,
      value: responses[field.name], // Pass the current value from the state
      onChange: handleInputChange,
    };
    return fieldTypes[field.type](field.name, fieldProps);
  };

  return !pageLoaded ? (
    <LinearProgress />
  ) : (
    <div>
      <h1>{title}</h1>
      <Card>
        <CardHeader
          subheader="Your progress will be saved automatically, you can come back and complete the form at any time."
          avatar={
            <Avatar>
              <Info />
            </Avatar>
          }
        />
      </Card>
      <br />
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => {
          return (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                <br />
                {step.type && renderField(step)}
                {/* <TextField
                  {...textFieldProps}
                  name={step.name}
                  onChange={handleText}
                  multiline
                  rows={4}
                  label={step.label}
                  value={responses[step.name] || ""}
                /> */}
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                      disabled={
                        !responses[step.name] ||
                        responses[step.name] === "" ||
                        loading
                      }
                    >
                      {index === steps.length - 1 ? "Finish" : "Continue"}
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>

      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        onClose={() => setSnackOpen(false)}
        color="primary"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="success" sx={{ width: "100%" }} variant="filled">
          Your progress has been saved!
        </Alert>
      </Snackbar>

      <CompleteDialog
        open={completeOpen}
        close={() => setCompleteOpen(false)}
        nextLink={category !== "competitorInfo" ? nextLinks[category] : null}
        siteId={siteId}
      />
    </div>
  );
}
