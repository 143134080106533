import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import React from "react";
import { useHistory } from "react-router-dom";
import { useDb } from "../../../contexts/DatabaseContext";
import ModelCard from "./ModelCard";

export default function Models() {
  const { GetOrgCollection } = useDb();
  const models = GetOrgCollection("models");

  const history = useHistory();
  return (
    <div>
      <h1>Your Custom Models</h1>
      <Card elevation={0} style={{ background: "rgba(0,0,0,0)" }}>
        <CardActions>
          <Button
            icon={<Add />}
            onClick={() => history.push("/create-model")}
            variant="contained"
            color="primary"
          >
            Create Model
          </Button>
        </CardActions>
        <CardContent>
          <Grid container spacing={3}>
            {models && models.map((model) => (
              <Grid key={model.id} item xs={12} md={4}>
                <ModelCard model={model} />
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
