import React from "react";
import { Route } from "react-router-dom";
import Forgot from "../components/auth/Forgot";
import SignIn from "../components/auth/SignIn";
import SignUp from "../components/auth/SignUp";

export default function PublicRoutes() {
  return (
    <>
      <Route exact path="/" component={SignIn} />
      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/forgot" component={Forgot} />

      {/* Catch Unrouted Routes */}
      {/* <Route exact path="*">
        <Redirect to="/" />
      </Route> */}
    </>
  );
}
