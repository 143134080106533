import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { FaCocktail } from "react-icons/fa";

export default function NoMoreReviews() {
  return (
    <div>
      <Card>
        <CardContent>
          <FaCocktail />
          <Typography>No more pages to review!</Typography>
        </CardContent>
      </Card>
    </div>
  );
}
