import React, { useState } from "react";
import {
  Editor,
  EditorState,
  ContentState,
  Modifier,
  CompositeDecorator,
} from "draft-js";
import "draft-js/dist/Draft.css";

// Placeholder component to style the placeholders
const Placeholder = (props) => {
  return (
    <span style={{ backgroundColor: "#e6f7ff", padding: "0 2px" }}>
      {props.children}
    </span>
  );
};

// Strategy to find placeholders
const findPlaceholder = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "PLACEHOLDER"
    );
  }, callback);
};

// Decorator for placeholders
const decorator = new CompositeDecorator([
  {
    strategy: findPlaceholder,
    component: Placeholder,
  },
]);

const PromptEditor = ({ initialPrompt, placeholders, onChange }) => {
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromText(initialPrompt),
      decorator
    )
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
    const currentContent = state.getCurrentContent();
    const promptText = currentContent.getPlainText();
    onChange(promptText);
  };

  const insertPlaceholder = (placeholder) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    const contentStateWithEntity = contentState.createEntity(
      "PLACEHOLDER",
      "MUTABLE",
      { placeholder }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const contentStateWithPlaceholder = Modifier.insertText(
      contentStateWithEntity,
      selectionState,
      `{{${placeholder}}}`,
      null,
      entityKey
    );

    const newEditorState = EditorState.push(
      editorState,
      contentStateWithPlaceholder,
      "insert-characters"
    );
    setEditorState(newEditorState);
  };

  return (
    <div>
      {Object.keys(placeholders).map((key) => (
        <button key={key} onClick={() => insertPlaceholder(key)}>
          Add {key}
        </button>
      ))}
      <div className="prompt-editor">
        <Editor editorState={editorState} onChange={handleEditorChange} />
      </div>
    </div>
  );
};

export default PromptEditor;
