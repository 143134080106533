import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { ArrowForward, Check, Delete, Info } from "@mui/icons-material";
import { Alert } from "@mui/material";
import React, { useState } from "react";
import { useDb } from "../../../../contexts/DatabaseContext";
import { selectFieldProps } from "../../../shared/constants";
import { paragraphize } from "../../../shared/utils/textFormattingUtils";
import Select from "react-select";
import { MdPreview } from "react-icons/md";

export default function ReviewContent({ link, modelId, updateReviewTime }) {
  const { GetCollectionFilter, updateStandardDoc, deleteStandardDoc } = useDb();
  const selections = GetCollectionFilter("selections", "modelId", modelId);
  const [selectionsPicked, setSelectionsPicked] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSelect = (v, e) => {
    setSelectionsPicked((prev) => {
      const newSelections = [...prev];
      const index = newSelections.findIndex((s) => s.name === e.name);
      if (index === -1) {
        newSelections.push({ name: e.name, value: v.value });
      } else {
        newSelections[index].value = v.value;
      }
      return newSelections;
    });
  };

  const handleSubmit = async () => {
    if (selectionsPicked.length !== selections.length) {
      window.alert("You have not selected all of the options.");
      return;
    }
    try {
      setLoading(true);
      await updateStandardDoc("links", link.id, {
        reviewed: true,
        resultJson: selectionsPicked,
      });
      updateReviewTime();
      setLoading(false);
    } catch (err) {
      window.alert(err.message);
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    const confirm = window.confirm(
      "Are you sure you want to delete this link? This action cannot be undone."
    );
    if (!confirm) return;
    else
      try {
        setLoading(true);
        await deleteStandardDoc("links", link.id);
        updateReviewTime();
        setLoading(false);
      } catch (err) {
        setLoading(false);
        window.alert(err.message);
      }
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card>
            <CardHeader
              title={link.title}
              subheader={link.url}
              avatar={
                <Avatar>
                  <Info />
                </Avatar>
              }
              action={
                <IconButton
                  color="primary"
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="large">
                  <MdPreview />
                </IconButton>
              }
            />
            <CardContent>
              <Alert severity="info">
                Please note: The text here may seem to be broken up into
                paragraphs mid-sentence. This is just to make it human-readable,
                do not be concerned.
              </Alert>
              <br />
              <Typography style={{ whiteSpace: "pre-line" }}>
                {paragraphize(link.bodyText, 45)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader
              title="Review Actions"
              subheader="Review the page and its content"
              avatar={
                <Avatar>
                  <Info />
                </Avatar>
              }
            />
            <CardContent>
              {selections &&
                selections.map((sel) => (
                  <Select
                    key={sel.id}
                    {...selectFieldProps}
                    options={sel.options}
                    placeholder={`Select ${sel.name} ...`}
                    className="rmf-select"
                    name={sel.name}
                    onChange={handleSelect}
                  />
                ))}
            </CardContent>
            <CardActions style={{ float: "right" }}>
              <Button
                color="secondary"
                startIcon={<Delete />}
                onClick={handleDelete}
              >
                Remove
              </Button>
              <Button
                color="primary"
                startIcon={<Check />}
                endIcon={<ArrowForward />}
                disabled={loading}
                onClick={handleSubmit}
              >
                Mark Reviewed
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
