import { Card, CardActionArea, CardHeader, Checkbox } from "@mui/material";
import React, { useState } from "react";
import CaptureCompanyInfoText from "./CaptureCompanyInfoText";

export default function CompanyChecklistItem({
  checked,
  title,
  subheader,
  field,
  siteId,
}) {
  const [addOpen, setAddOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(checked);

  const confirmCheck = () => {
    setIsChecked(true);
  };

  return (
    <>
      <Card className="company-checklist-item">
        <CardActionArea onClick={() => setAddOpen(true)}>
          <CardHeader
            title={title}
            subheader={subheader}
            avatar={<Checkbox checked={isChecked} />}
          />
        </CardActionArea>
      </Card>

      <CaptureCompanyInfoText
        open={addOpen}
        close={() => setAddOpen(false)}
        field={field}
        question={title}
        subheader={subheader}
        siteId={siteId}
        confirmCheck={confirmCheck}
      />
    </>
  );
}
