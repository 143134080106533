import { Container, Grid } from "@mui/material";
import React, { useState } from "react";
import BackLink from "../../../shared/BackLink";
import ModelType from "./ModelType";
import { FcDocument } from "react-icons/fc";
import AddModelName from "./AddModelName";

export default function CreateModel() {
  const [addOpen, setAddOpen] = useState(false);
  const [modelType, setModelType] = useState(null);

  const toggleAddOpen = () => {
    setAddOpen(!addOpen);
  };

  const handleSelect = (modelType) => {
    setModelType(modelType);
    toggleAddOpen();
  };

  return (
    <div>
      <h1>Create / Add New Model</h1>
      <BackLink />
      <Container>
        <div style={{ textAlign: "center" }}>
          <h2>Select Model Type</h2>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <ModelType
              title="Text Classification"
              subheader="Classify text into categories"
              icon={<FcDocument />}
              content={
                "Create set categories and train your model to classify text into those categories."
              }
              onClick={() => handleSelect("text-classification")}
            />
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <ModelType
              title="Text Generation from Classes"
              subheader="Generate Text from Categories"
              icon={<FcDocument />}
              content={"Generate text from preset categories."}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ModelType
              title="Page Classification"
              subheader="Generate Text from Categories"
              icon={<FcDocument />}
              content={
                "Create set categories and train your model to classify a web page into those categories."
              }
              onClick={() => handleSelect("link-classification")}
            />
          </Grid> */}
        </Grid>
      </Container>

      {modelType && (
        <AddModelName
          open={addOpen}
          close={toggleAddOpen}
          modelType={modelType}
        />
      )}
    </div>
  );
}
