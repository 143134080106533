import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useState } from "react";
import Select from "react-select";
import {
  buyerPersonaOptions,
  buyerStageOptions,
  contentFormatOptions,
  selectFieldProps,
  textFieldProps,
  toneOptions,
} from "../../../../shared/constants";
import { functions } from "../../../../../firebase";
import { Info, AssignmentTurnedIn } from "@mui/icons-material";
import { useDb } from "../../../../../contexts/DatabaseContext";
import { useHistory } from "react-router-dom";
import GeneratedTopics from "./GeneratedTopics";
import WordCount from "../manage-content/content-settings/WordCount";

export default function TopicIdeas({ siteId, selections }) {
  const history = useHistory();
  const { addStandardDoc, GetCollectionFilter } = useDb();
  const generations = GetCollectionFilter("generate", "siteId", siteId);
  const links = GetCollectionFilter("links", "siteId", siteId);

  const existingPageOptions =
    links &&
    links.map((link) => ({
      label: link.title,
      value: link.id,
    }));

  const [selectionsPicked, setSelectionsPicked] = useState([]);
  const [topicDialogOpen, setTopicDialogOpen] = useState(false);
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [referenceContent, setReferenceContent] = useState(null);
  const [customTopic, setCustomTopic] = useState("");

  const [selectedBuyerStage, setSelectedBuyerStage] = useState();
  const [selectedBuyerPersona, setSelectedBuyerPersona] = useState();

  const [selectedFormat, setSelectedFormat] = useState("");
  const [selectedTone, setSelectedTone] = useState("");

  const [wordCount, setWordCount] = useState(150);

  const handleGenerate = async () => {
    setError(null);
    setLoading(true);
    setTopicDialogOpen(true);
    try {
      const generateTopicIdeas = functions.httpsCallable("generateTopicIdeas");

      const result = await generateTopicIdeas({
        siteId,
        targetStage: selectedBuyerStage,
        targetPersona: selectedBuyerPersona,
      });
      console.log(result);
      if (result.data.result && result.data.result.topicIdeas) {
        setTopics(result.data.result.topicIdeas);
      }
      setLoading(false);
    } catch (err) {
      setError("Error getting topic ideas. Please try again?");
      console.log(err);
      setLoading(false);
    }
  };

  const handleTopicSelect = async (topic) => {
    const contentConfig = selectionsPicked.reduce(
      (accumulator, currentObject) => {
        accumulator[currentObject.name] = currentObject.value;
        return accumulator;
      },
      {}
    );
    let contextConfig = {
      topic,
    };
    if (referenceContent) {
      contextConfig.referenceContent = referenceContent;
    }

    try {
      const generateId = await addStandardDoc("generate", {
        siteId,
        topic,
        buyerPersona: selectedBuyerPersona,
        buyerStage: selectedBuyerStage,
        tone: selectedTone,
        format: selectedFormat,
      });
      setTopicDialogOpen(false);
      history.push(`/content-creator/${siteId}/${generateId}`);
    } catch (err) {
      window.alert(err.message);
    }
  };

  return (
    <div>
      <Container>
        <Card>
          <CardHeader
            title="New Content: Generate Topic Ideas"
            subheader="Based on your content classification selections, we will generate topic ideas for you first."
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Card className="company-checklist-item">
                  <CardHeader
                    title="Content Targeting"
                    avatar={
                      <Avatar>
                        <AssignmentTurnedIn />
                      </Avatar>
                    }
                  />
                  <CardContent>
                    <Select
                      {...selectFieldProps}
                      options={buyerStageOptions}
                      placeholder={"Select Buyer Stage ..."}
                      className="rmf-select"
                      name={"buyer_stage"}
                      onChange={(val) => setSelectedBuyerStage(val.value)}
                    />
                    <Select
                      {...selectFieldProps}
                      options={buyerPersonaOptions}
                      placeholder={"Select Buyer Persona ..."}
                      className="rmf-select"
                      name={"buyer_persona"}
                      onChange={(val) => setSelectedBuyerPersona(val.value)}
                    />
                    <Select
                      {...selectFieldProps}
                      options={contentFormatOptions}
                      placeholder={"Select Content Format ..."}
                      className="rmf-select"
                      name={"buyer_persona"}
                      onChange={(val) => setSelectedFormat(val.value)}
                    />
                    <Select
                      {...selectFieldProps}
                      options={toneOptions}
                      placeholder={"Select Tone ..."}
                      className="rmf-select"
                      name={"buyer_persona"}
                      onChange={(val) => setSelectedTone(val.value)}
                    />
                    <WordCount
                      wordCount={wordCount}
                      setWordCount={setWordCount}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card className="company-checklist-item">
                  <CardHeader
                    title="Reference Content"
                    subheader="You can reference other content from your site or the web here for more ideas."
                    avatar={
                      <Avatar>
                        <Info />
                      </Avatar>
                    }
                  />
                  <CardContent>
                    <Stack spacing={1}>
                      <Select
                        {...selectFieldProps}
                        placeholder={
                          "Select one or more pages from your site to reference ..."
                        }
                        isMulti
                        options={existingPageOptions}
                      />
                      <TextField
                        {...textFieldProps}
                        placeholder={"Enter an external URL to reference ..."}
                        type="url"
                      />
                      <Select
                        {...selectFieldProps}
                        placeholder={
                          "Select one or more files from your Media Library ..."
                        }
                        isMulti
                      />
                    </Stack>
                  </CardContent>
                </Card>
                <br />
              </Grid>
            </Grid>
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={handleGenerate}
              disabled={loading || !selectedBuyerPersona || !selectedBuyerStage}
            >
              Generate Topic Ideas
            </Button>
            <GeneratedTopics
              open={topicDialogOpen}
              close={() => setTopicDialogOpen(false)}
              topics={topics}
              setTopics={setTopics}
              customTopic={customTopic}
              setCustomTopic={setCustomTopic}
              handleTopicSelect={handleTopicSelect}
              loading={loading}
              error={error}
              retryGenerate={handleGenerate}
            />
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
