import { Button, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import logo from "../../img/stacked.png";
import Select from "react-select";
import { countryCodes, textFieldProps } from "../shared/constants";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    margin: "10px 0",
  },
  btn: {
    margin: "15px 0",
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const { signUp } = useAuth();

  const [state, setState] = useState({
    error: null,
    loading: false,
    data: {},
  });

  const handleChange = (e) => {
    setState({
      ...state,
      data: {
        ...state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setState({
      ...state,
      error: null,
      loading: true,
    });
    try {
      await signUp(state.data.email, state.data.password, state.data);
      window.location.reload();
    } catch (err) {
      setState({
        ...state,
        error: err.message,
        loading: false,
      });
    }
  };

  return (
    <div>
      <Container maxWidth="xs">
        <div className={classes.paper}>
          <img src={logo} width={200} />
          <Typography className={classes.title}>Sign Up</Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="First Name"
              name="firstName"
              onChange={handleChange}
              {...textFieldProps}
              fullWidth
              required
            />
            <TextField
              label="Last Name"
              name="lastName"
              onChange={handleChange}
              {...textFieldProps}
              fullWidth
              required
            />
            <TextField
              label="Company Name"
              placeholder="Leave blank if you are not a company"
              name="companyName"
              onChange={handleChange}
              {...textFieldProps}
              fullWidth
            />

            <TextField
              label="Email"
              type="email"
              name="email"
              onChange={handleChange}
              {...textFieldProps}
              fullWidth
              required
            />
            <TextField
              label="Choose a Password"
              type="password"
              name="password"
              onChange={handleChange}
              {...textFieldProps}
              fullWidth
              required
            />
            <Select
              options={countryCodes}
              placeholder="Select/Search Country ..."
              onChange={(e) => {
                setState({
                  ...state,
                  data: {
                    ...state.data,
                    country: e.value,
                  },
                });
              }}
            />
            <Button
              color="primary"
              variant="contained"
              fullWidth
              className={classes.btn}
              size="large"
              type="submit"
              disabled={
                state.loading ||
                !state.data.country ||
                !state.data.firstName ||
                !state.data.lastName ||
                !state.data.email ||
                !state.data.password
              }
            >
              Submit
            </Button>
          </form>
          <Link to="/" variant="body2">
            Already have an account? Log In
          </Link>
        </div>
        {state.error && (
          <Typography color="secondary">{state.error}</Typography>
        )}
      </Container>
    </div>
  );
}
