import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  LinearProgress,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FcClapperboard } from "react-icons/fc";
import JsonLPreview from "../utilities/ JsonLPreview";
import Select from "react-select";
import { selectFieldProps, textFieldProps } from "../../../../shared/constants";
import { useDb } from "../../../../../contexts/DatabaseContext";
import { useParams, useHistory } from "react-router-dom";
import BackLink from "../../../../shared/BackLink";
import { Add, Share } from "@mui/icons-material";
import EntrySuccess from "../utilities/EntrySuccess";

export default function TextClassificationView() {
  const { modelId } = useParams();
  const history = useHistory();
  const { GetOrgCollectionWithFilter, addStandardDoc, getStandardDoc } =
    useDb();
  const selections = GetOrgCollectionWithFilter(
    "selections",
    "modelId",
    modelId
  );
  const [textInput, setTextInput] = useState("");
  const [selectionsPicked, setSelectionsPicked] = useState([]);
  const [model, setModel] = useState();
  const [loading, setLoading] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [json, setJson] = useState();

  const retrieveModel = async () => {
    const m = await getStandardDoc("models", modelId);
    setModel(m);
  };

  useEffect(() => {
    const unsubscribe = retrieveModel();
    return unsubscribe;
  }, [modelId]);

  const handleSelect = (v, e) => {
    setSelectionsPicked((prev) => {
      const newSelections = [...prev];
      const index = newSelections.findIndex((s) => s.name === e.name);
      if (index === -1) {
        newSelections.push({ name: e.name, value: v.value });
      } else {
        newSelections[index].value = v.value;
      }
      return newSelections;
    });
    updateJson();
  };

  const handleTextChange = (e) => {
    setTextInput(e.target.value);
    updateJson();
  };

  const updateJson = () => {
    const newLineText = textInput.replace(/\n/g, "\\n");
    const maxLength = 150;
    const promptSelections =
      selections && selections.map((sel) => sel.name).join(", ");

    const plainJson = {
      prompt: `Classify The Following Text by one of the categories: ${promptSelections}:
      ${selections && selections.map((sel) => sel.name).join(", ")}

      Text: ${textInput} ... 
      PROMPT_SEPARATOR
      `,
      completion: `${
        selectionsPicked &&
        selectionsPicked.map((sel) => `"${sel.name}": "${sel.value}"`)
      }
      STOPSTOP
      `,
    };
    setJson(plainJson);
  };

  const handleReset = () => {
    setTextInput(" ");
    setSuccessOpen(false);
  };

  const handleSuccess = () => {
    setSuccessOpen(true);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await addStandardDoc("entries", {
        modelId,
        selections: selectionsPicked,
        textInput,
        modelType: model.modelType,
        json,
      });
      setLoading(false);
      handleSuccess();
    } catch (error) {
      window.alert(error.message);
      setLoading(false);
    }
  };

  return !model ? (
    <LinearProgress />
  ) : (
    <div style={{ marginTop: 25 }}>
      <Container>
        <BackLink />
        <Card>
          <CardHeader
            title="View"
            subheader="Model Input Form"
            avatar={<FcClapperboard />}
            action={
              <Button startIcon={<Share />} color="primary">
                Share
              </Button>
            }
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={0}>
                  {selections &&
                    selections.map((sel) => (
                      <Grid key={sel.id} item xs={6}>
                        <div
                          style={{
                            marginRight: 5,
                          }}
                        >
                          <Select
                            {...selectFieldProps}
                            options={sel.options}
                            placeholder={`Select ${sel.name} ...`}
                            className="rmf-select"
                            name={sel.name}
                            onChange={handleSelect}
                          />
                        </div>
                      </Grid>
                    ))}
                </Grid>
                <TextField
                  {...textFieldProps}
                  label="Text to classify"
                  multiline
                  minRows={10}
                  value={textInput}
                  onChange={handleTextChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <JsonLPreview
                  selections={selectionsPicked}
                  textInput={textInput}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              disabled={
                !textInput ||
                selectionsPicked.length !== selections.length ||
                loading
              }
              onClick={handleSubmit}
            >
              Add to Training Data
            </Button>
          </CardActions>
        </Card>
      </Container>

      <EntrySuccess
        open={successOpen}
        close={() => setSuccessOpen(false)}
        doAnother={handleReset}
        goHome={() => history.push("/")}
      />
    </div>
  );
}
