import { ArrowBack, ArrowForward, Info } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Avatar,
  IconButton,
  TextField,
  InputAdornment,
  Button,
  Alert,
  Box,
} from "@mui/material";
import React from "react";
import { textFieldProps } from "../../../../shared/constants";
import thinking from "../../../../../lottie/thinking.json";
import AnimationContainer from "../../../../shared/animations/AnimationContainer";

export default function GeneratedTopics({
  open,
  close,
  topics,
  setTopics,
  loading,
  handleTopicSelect,
  customTopic,
  setCustomTopic,
  error,
  retryGenerate,
}) {
  const handleClose = () => {
    setCustomTopic("");
    setTopics([]);
    close();
  };

  return (
    <Dialog maxWidth="lg" fullWidth onClose={handleClose} open={open}>
      <DialogTitle>Select Topics</DialogTitle>
      <DialogContent>
        {loading && (
          <AnimationContainer
            animationData={thinking}
            message="Generating Topics... This may take over a minute, please be patient."
          />
        )}
        {error && (
          <div>
            <Box textAlign="center">
              <Alert severity="error">{error}</Alert>
              <br />
              <Button
                color="primary"
                variant="contained"
                onClick={retryGenerate}
              >
                Retry
              </Button>
            </Box>
          </div>
        )}
        {topics.length > 0 && !loading && !error && (
          <Card className="company-checklist-item">
            <CardHeader
              title="Topic Ideas"
              subheader="Select one of the topic ideas below to start creating content. Or Click Generate again to come up with new ideas."
            />
            <CardContent>
              <Grid container spacing={3}>
                {topics.length > 0 &&
                  !loading &&
                  topics.map((topic) => (
                    <Grid item xs={12} md={6} key={topic}>
                      <Card>
                        <CardHeader
                          title={topic}
                          avatar={
                            <Avatar>
                              <Info />
                            </Avatar>
                          }
                          action={
                            <IconButton
                              onClick={(e) => handleTopicSelect(topic)}
                            >
                              <ArrowForward />
                            </IconButton>
                          }
                        />
                      </Card>
                    </Grid>
                  ))}
              </Grid>
              <br />
              -- OR Type your own Topic --
              <TextField
                {...textFieldProps}
                label="Topic"
                value={customTopic}
                onChange={(e) => setCustomTopic(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disabled={loading || !customTopic}
                        onClick={() => handleTopicSelect(customTopic)}
                      >
                        <ArrowForward />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </CardContent>
          </Card>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
