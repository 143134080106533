export const jobtitleQuestions = [
  {
    label: "Responsibilities",
    description:
      "What are the job title's responsibilities? Separate each responsibility with a comma.",
    name: "responsiblities",
    type: "text",
    props: {
      type: "text",
      label: "Responsibilities",
    },
  },
  {
    label: "Trade Events",
    description:
      "What trade events does the job title attend? Separate each trade event with a comma.",
    name: "tradeEvents",
    type: "text",
    props: {
      type: "text",
      label: "Trade Events",
    },
  },
];
