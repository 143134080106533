import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import {
  textFieldProps,
  roles,
  selectFieldProps,
} from "../../shared/constants";
import Select from "react-select";
import { Alert } from "@mui/material";

export default function NewMember({ open, close }) {
  const [data, setData] = useState({});

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleClose = () => {
    setData({});
    close();
  };

  return (
    <Dialog open={open} maxWidth="sm" onClose={handleClose} fullWidth>
      <DialogTitle>Add Team Member</DialogTitle>
      <DialogContent>
        <TextField
          label="Email"
          type="email"
          {...textFieldProps}
          name="email"
          onChange={handleTextChange}
        />
        <TextField
          label="First Name"
          {...textFieldProps}
          name="firstName"
          onChange={handleTextChange}
        />
        <TextField
          label="Last Name"
          {...textFieldProps}
          name="lastName"
          onChange={handleTextChange}
        />
        <Select
          {...selectFieldProps}
          options={roles}
          className="rmf-select"
          placeholder="Select Role ..."
        />
        <Alert severity="info">
          You can learn more about user roles{" "}
          <a href="#">
            <b>here</b>
          </a>
          .
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
