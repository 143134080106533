import { Container, LinearProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDb } from "../../../../../contexts/DatabaseContext";
import BackLink from "../../../../shared/BackLink";
import InfoWizard from "../info-wizard/InfoWizard";
import { firmographicQuestions as steps } from "./firmoQuestions";

export default function FirmographicsInfo() {
  const { siteId, firmographicsId } = useParams();
  const { getStandardDoc } = useDb();
  const [info, setInfo] = React.useState();

  const loadInfo = async () => {
    const infoDoc = await getStandardDoc("firmographics", firmographicsId);
    setInfo(infoDoc);
  };

  useEffect(() => {
    const unsubscribe = loadInfo();
    return unsubscribe;
  }, [siteId, firmographicsId]);

  return !info ? (
    <LinearProgress />
  ) : (
    <div>
      <Container>
        <BackLink override={`/targeting/${siteId}/`} />

        <InfoWizard
          steps={steps}
          siteId={siteId}
          title={info.name}
          category={"firmographics"}
          collection="firmographics"
          documentId={firmographicsId}
        />
      </Container>
    </div>
  );
}
