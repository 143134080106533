import { Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import React from "react";
import { useHistory } from "react-router-dom";

export default function BackLink({ override, label }) {
  const history = useHistory();

  return (
    <div className="back-link">
      <Button
        color="primary"
        startIcon={<ArrowBack />}
        onClick={() => {
          !override ? history.goBack() : history.push(override);
        }}
      >
        {label || "Back"}
      </Button>
    </div>
  );
}
