import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useState } from "react";
import NewInfoTypeDialog from "../NewInfoTypeDialog";
import InfoTable from "../InfoTable";

export default function JobTitles({ siteId }) {
  const [addOpen, setAddOpen] = useState(false);
  return (
    <div>
      <Button onClick={() => setAddOpen(true)} startIcon={<Add />}>
        Add Job Titles
      </Button>

      <InfoTable siteId={siteId} infoTypeCollection="jobtitles" />

      <NewInfoTypeDialog
        open={addOpen}
        close={() => setAddOpen(false)}
        siteId={siteId}
        infoTypeCollection="jobtitles"
        infoTypeLabel="Job Title"
      />
    </div>
  );
}
