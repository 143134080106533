import {
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import Select from "react-select";
import { selectFieldProps, textFieldProps } from "../../../../shared/constants";
import { useDb } from "../../../../../contexts/DatabaseContext";
import { QuestionMarkOutlined, Save } from "@mui/icons-material";

export default function AddCombinedBuyerPersona({ open, close, siteId }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [firmographicsSelected, setFirmographics] = useState();
  const [jobTitlesSelected, setJobTitles] = useState();
  const [personaAttributesSelected, setPersonaAttributes] = useState();
  const [loading, setLoading] = useState(false);

  const { GetOrgCollectionWithFilter, addStandardDoc } = useDb();
  const firmographics = GetOrgCollectionWithFilter(
    "firmographics",
    "siteId",
    siteId
  );
  const jobTitles = GetOrgCollectionWithFilter("jobtitles", "siteId", siteId);
  const personaAttributes = GetOrgCollectionWithFilter(
    "buyerPersonas",
    "siteId",
    siteId
  );

  const selectQuestions = [
    {
      label: "Select Firmographics ...",
      options: firmographics,
      tooltipMsg:
        "You can create Firmographics by clicking the Add Firmographics button in the targeting section of the previous page.",
      name: "firmographics",
    },
    {
      label: "Select Job Titles ...",
      options: jobTitles,
      tooltipMsg:
        "You can create Job Titles by clicking the Add Job Titles button in the targeting section of the previous page.",
      name: "jobTitles",
    },
    {
      label: "Select Persona Attributes ...",
      options: personaAttributes,
      tooltipMsg:
        "You can create Persona Attributes by clicking the Add Persona Attributes button in the targeting section of the previous page.",
      name: "personaAttributes",
    },
  ];

  const handleClose = () => {
    // Clear all state
    setLoading(false);
    setName("");
    setDescription("");
    setFirmographics();
    setJobTitles();
    setPersonaAttributes();
    close();
  };

  const handleSelect = (val, e) => {
    const infoType = { id: val.id, name: val.name };
    switch (e.name) {
      case "firmographics":
        setFirmographics(infoType);
        break;
      case "jobTitles":
        setJobTitles(infoType);
        break;
      case "personaAttributes":
        setPersonaAttributes(infoType);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const newPersona = {
        name,
        description,
        firmographics: firmographicsSelected,
        jobTitles: jobTitlesSelected,
        personaAttributes: personaAttributesSelected,
        siteId,
      };
      await addStandardDoc("combinedPersonas", newPersona);
      handleClose();
    } catch (err) {
      window.alert(err.message);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add Buyer Persona</DialogTitle>
      <DialogContent>
        <TextField
          {...textFieldProps}
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {selectQuestions.map((question, index) => (
          <div key={index}>
            <CardHeader
              style={{ padding: 0 }}
              subheader={
                <Select
                  options={question.options}
                  placeholder={question.label}
                  onChange={handleSelect}
                  className="rmf-select"
                  {...selectFieldProps}
                  name={question.name}
                />
              }
              action={
                <Tooltip title={question.tooltipMsg}>
                  <IconButton>
                    <QuestionMarkOutlined />
                  </IconButton>
                </Tooltip>
              }
            />
          </div>
        ))}
        <TextField
          {...textFieldProps}
          label="Additional Instructions / Descriptions"
          value={description}
          multiline
          rows={4}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter a description for this persona, or anything else you'd like to add that would help the AI relate to this persona or its attributes."
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          startIcon={<Save />}
          disabled={
            !name ||
            !description ||
            !firmographicsSelected ||
            !jobTitlesSelected ||
            !personaAttributesSelected ||
            loading
          }
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
