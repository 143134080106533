import React from "react";
import Chart from "react-apexcharts";

export default function CombinedChart({ links }) {
  // Define the buyer personas and stages
  const buyerPersonas = [
    "End User",
    "Decision Maker",
    "Influencer",
    "Financial Authority",
    "Blocker",
  ];
  const buyerStages = ["Learn", "Solve", "Compare", "Purchase", "Loyalty"];

  // Initialize the data structure for counts
  const counts = buyerStages.map((stage) => {
    return buyerPersonas.map(() => 0);
  });

  // Create a mapping for buyer stages
  const stageIndexMap = buyerStages.reduce((acc, stage, index) => {
    acc[stage] = index;
    return acc;
  }, {});

  // Create a mapping for buyer personas
  const personaIndexMap = buyerPersonas.reduce((acc, persona, index) => {
    acc[persona] = index;
    return acc;
  }, {});

  // Update counts based on the links data
  links.forEach((item) => {
    const stage = item.buyer_journey;
    const persona = item.buyer_persona;
    if (
      stageIndexMap.hasOwnProperty(stage) &&
      personaIndexMap.hasOwnProperty(persona)
    ) {
      counts[stageIndexMap[stage]][personaIndexMap[persona]]++;
    }
  });

  // Prepare the series data for ApexCharts
  const series = buyerPersonas.map((persona, personaIndex) => {
    return {
      name: persona,
      data: counts.map((stageCounts) => stageCounts[personaIndex]),
    };
  });

  const options = {
    chart: {
      type: "bar",
      stacked: false,
    },
    xaxis: {
      categories: buyerStages,
    },
    fill: {
      colors: ["#4ab7c4", "#f39c12", "#e74c3c", "#2ecc71", "#9b59b6"],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
        columnWidth: "50%",
        endingShape: "flat",
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
  };

  return (
    <div>
      <Chart
        options={options}
        series={series}
        type="bar"
        width="100%"
        height={300}
      />
    </div>
  );
}
