import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  LinearProgress,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import SiteContent from "./site_content/SiteContent";
import { HealingRounded, Rocket } from "@mui/icons-material";
import SiteSetupPending from "./SiteSetupPending";
import { useDb } from "../../../../../contexts/DatabaseContext";
import SiteSetupComplete from "./SiteSetupComplete";

export default function HealthCenter() {
  const { siteId } = useParams();
  const { getStandardDoc } = useDb();
  const [site, setSite] = useState();

  const loadSite = async () => {
    const s = await getStandardDoc("sites", siteId);
    setSite(s);
  };

  useEffect(() => {
    return loadSite();
  }, [siteId]);

  if (!site) {
    return <LinearProgress />;
  }

  return (
    <Stack spacing={2}>
      <Card className="site-detail-header">
        <CardHeader title="Health Center" />
      </Card>
      <Card>
        <CardHeader title="Site Analysis Status" avatar={<HealingRounded />} />
        <CardContent>
          {!site.setup_steps?.elevator_pitch ||
          !site.setup_steps?.what_you_do ? (
            <SiteSetupPending site={site} />
          ) : (
            <SiteSetupComplete site={site} />
          )}
        </CardContent>
      </Card>
      <SiteContent siteId={siteId} />
    </Stack>
  );
}
