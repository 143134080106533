import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
} from "@mui/material";
import React from "react";

export default function ModelType({
  title,
  subheader,
  icon,
  content,
  onClick,
}) {
  return (
    <Card>
      <CardActionArea onClick={onClick}>
        <CardHeader title={title} subheader={subheader} avatar={icon} />
        <CardContent>{content}</CardContent>
      </CardActionArea>
    </Card>
  );
}
