import { ArrowForward } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import { useDb } from "../../../../contexts/DatabaseContext";
import { textFieldProps } from "../../../shared/constants";
import { useHistory } from "react-router-dom";

export default function NewPersonaDialog({ open, close, siteId }) {
  const [personaName, setPersonaName] = useState("");
  const [loading, setLoading] = useState(false);
  const { addStandardDoc } = useDb();
  const history = useHistory();

  const handleClose = () => {
    setLoading(false);
    setPersonaName("");
    close();
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const peronaId = await addStandardDoc("buyerPersonas", {
        name: personaName,
        siteId,
        label: personaName,
        value: personaName,
      });
      history.push(`/buyer-persona-info/${siteId}/${peronaId}`);
      handleClose();
    } catch (err) {
      window.alert(err.message);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add Persona Attributes</DialogTitle>
      <DialogContent>
        <TextField
          {...textFieldProps}
          label="Persona Name / Title"
          onChange={(e) => setPersonaName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          endIcon={<ArrowForward />}
          disabled={loading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
