import {
  Card,
  CardContent,
  CardHeader,
  Snackbar,
  Typography,
} from "@mui/material";
import { Alert } from "@mui/material";
import React, { useState } from "react";
import { FcElectronics } from "react-icons/fc";
import Select from "react-select";
import { useDb } from "../../../../../contexts/DatabaseContext";
import { baseModels, selectFieldProps } from "../../../../shared/constants";
import { baseModelDefaults } from "./baseModelDefaults";

export default function ModelSettings({ model, recommendedModel }) {
  const { updateStandardDoc } = useDb();
  const [snackOpen, setSnackOpen] = useState(false);

  const handleModelChange = async (v) => {
    try {
      await updateStandardDoc("models", model.id, {
        baseModel: v.value,
      });
      setSnackOpen(true);
    } catch (err) {
      window.alert(err.message);
    }
  };

  const currentModel = baseModels.find((m) => m.value === model.baseModel);

  return (
    <Card>
      <CardHeader
        avatar={<FcElectronics />}
        title="Model Settings"
        subheader="Set model settings"
      />
      <CardContent>
        <Typography>OpenAI Base Model:</Typography>
        {currentModel && (
          <Select
            options={baseModels}
            placeholder="Change base model ..."
            {...selectFieldProps}
            className="rmf-select"
            //   defaultValue={baseModels.find((m) => m.value === model.baseModel)}
            defaultValue={currentModel}
            onChange={handleModelChange}
          />
        )}
        {/* <Alert severity="info">
          The recommended base model for this type of task is:{" "}
          <b>{baseModelDefaults[model.modelType]}</b>.<br /> For more info, see
          OpenAi's best practices for fine-tuning models.
          <br />
          <br />
          <b>
            <a
              style={{ textDecoration: "underline" }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://platform.openai.com/docs/guides/fine-tuning/general-best-practices"
            >
              OpenAI FT Best Practices
            </a>
          </b>
        </Alert> */}
      </CardContent>

      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={() => setSnackOpen(false)}
        message="Model settings updated."
        color="success"
      />
    </Card>
  );
}
