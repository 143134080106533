import {
  BusinessRounded,
  CheckCircle,
  RadioButtonUncheckedRounded,
} from "@mui/icons-material";
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  LinearProgress,
  List,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CaptureCompanyInfoText from "./CaptureCompanyInfoText";
import CompanyChecklistItem from "./CompanyChecklistItem";
import { useDb } from "../../../../../contexts/DatabaseContext";

export default function CompanyChecklist({ siteId }) {
  const { getStandardDoc } = useDb();
  const [site, setSite] = useState();

  const loadSite = async () => {
    const s = await getStandardDoc("sites", siteId);
    setSite(s);
  };

  useEffect(() => {
    return loadSite();
  }, [siteId]);

  if (!site) {
    return <LinearProgress />;
  }

  console.log(site);

  return (
    <Card>
      <CardHeader
        title="Company Info Checklist"
        avatar={<BusinessRounded />}
        subheader="The more information you give us about your business the better we can understand your content"
      />
      <CardContent>
        <List>
          <CompanyChecklistItem
            siteId={siteId}
            field={"elevator_pitch"}
            title="Elevator Pitch"
            subheader="Tell us about your company"
            checked={site.setup_steps?.elevator_pitch}
          />
          <CompanyChecklistItem
            siteId={siteId}
            field={"what_you_do"}
            title="What do you do?"
            subheader="Enter a brief description of your product and services"
            checked={site.setup_steps?.what_you_do}
          />
          <CompanyChecklistItem
            siteId={siteId}
            field={"why_you_do_it"}
            title="Why do you do it?"
            subheader="Enter a brief description why your company exists"
            checked={site.setup_steps?.why_you_do_it}
          />
          <CompanyChecklistItem
            siteId={siteId}
            field={"how_you_do_it"}
            title="How do you do it?"
            subheader="Enter a brief description of how your company does this"
            checked={site.setup_steps?.how_you_do_it}
          />
          <CompanyChecklistItem
            siteId={siteId}
            field={"value_props"}
            title="Value Propositions"
            subheader="Enter your value propositions"
            checked={site.setup_steps?.value_props}
          />
        </List>
      </CardContent>

      <CaptureCompanyInfoText siteId={siteId} />
    </Card>
  );
}
