import React from "react";
import { useParams } from "react-router-dom";
import InfoBuilder from "../InfoBuilder";
import { jobtitleQuestions } from "./jobTitleQuestions";

export default function JobTitlesInfo() {
  const { siteId, jobtitleId } = useParams();
  return (
    <div>
      <InfoBuilder
        steps={jobtitleQuestions}
        infoTypeCollection="jobtitles"
        documentId={jobtitleId}
        siteId={siteId}
      />
    </div>
  );
}
