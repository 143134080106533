import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  btn: {
    marginLeft: 20,
  },
  root: {
    marginLeft: 40,
  },
}));

export default function CustomerHorizontalMenu() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Link to="/">
        <Button className={classes.btn} size="large">
          Your Sites
        </Button>
      </Link>
      {/* <Link to="/">
        <Button className={classes.btn} size="large">
          Settings
        </Button>
      </Link> */}
    </div>
  );
}
