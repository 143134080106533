import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Creatable from "react-select/creatable";
import { selectFieldProps, textFieldProps } from "../../../../shared/constants";
import { InfoRounded, Save } from "@mui/icons-material";
import { useDb } from "../../../../../contexts/DatabaseContext";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const toolTipContents = {
  influencer:
    "The influencer is responsible for finding solutions to specific problems the company is facing. Most likely was told by the decision maker to find multiple solutions to compare. They are concerned with finding companies who have the most features and capabilities who have experience working with other companies in the same industry as theirs along with pricing.",
  endUser:
    "The end user is the person who will be using the product or service the most. They will want to know how this product, or services is different from what they are doing today, Why is it better, how long does it takes to learn, what does training looks like, who do they go to with questions or support issues.",
  decisionMaker:
    "These people want to know how your solution is better from what they are doing today, will it make their company and employees more efficient or effective, ROI, whether or not you have worked with other companies in the same industry, case studies and industry research or knowledge etc.",
  finAuthority:
    "These people will want to know why spending the money makes sense for the company, what the terms and conditions are, payment options, escalation policies, cancelation polices and ROI etc.",
  blocker:
    "These people are hesitant to change and often bring up data security issues, operational disruption, cost and other topics.",
};

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="right" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 14,
    borderRadius: 10,
    boxShadow: `0px 4px 0px #4ab7c4`,
    border: `1px solid #4ab7c4`,
  },
}));

export default function EditDemographic({ open, close, demoId }) {
  const { updateStandardDoc, getStandardDoc } = useDb();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [industryLabel, setIndustryLabel] = useState();
  const [loading, setLoading] = useState(false);

  const personas = [
    {
      name: "influencers",
      label: "Influencers",
      toolTip: toolTipContents["influencer"],
    },
    {
      name: "endUsers",
      label: "End Users",
      toolTip: toolTipContents["endUser"],
    },
    {
      name: "decisionMakers",
      label: "Decision Makers",
      toolTip: toolTipContents["decisionMaker"],
    },
    {
      name: "financialAuthorities",
      label: "Financial Authorities",
      toolTip: toolTipContents["finAuthority"],
    },
    {
      name: "blockers",
      label: "Blockers",
      toolTip: toolTipContents["blocker"],
    },
  ];

  const [data, setData] = useState({
    influencers: {
      list: [],
      challenges: "",
      excludes: [],
    },
    endUsers: {
      list: [],
      challenges: "",
      excludes: [],
    },
    decisionMakers: {
      list: [],
      challenges: "",
      excludes: [],
    },
    financialAuthorities: {
      list: [],
      challenges: "",
      excludes: [],
    },
    blockers: {
      list: [],
      challenges: "",
      excludes: [],
    },
  });

  const loadPersonaData = async () => {
    const demoSnap = await getStandardDoc("demographics", demoId);
    if (demoSnap.data) {
      setData(demoSnap.data);
    }
    setIndustryLabel(demoSnap.industry);
    setDataLoaded(true);
  };

  useEffect(() => {
    if (!dataLoaded) {
      return loadPersonaData();
    }
  }, []);

  const handlePersonaSelect = (val, e) => {
    const valArray = val.map((v) => ({
      value: v.value,
      label: v.label,
    }));
    setData({
      ...data,
      [e.name]: {
        ...data[e.name],
        list: valArray,
      },
    });
  };

  const handlePersonaExcludeSelect = (val, e) => {
    const valArray = val.map((v) => ({
      value: v.value,
      label: v.label,
    }));
    setData({
      ...data,
      [e.name]: {
        ...data[e.name],
        excludes: valArray,
      },
    });
  };

  const handleChallengeText = (e) => {
    setData({
      ...data,
      [e.target.name]: {
        ...data[e.target.name],
        challenges: e.target.value,
      },
    });
  };

  const handleClose = () => {
    setData({
      influencers: {
        list: [],
        challenges: "",
      },
      endUsers: {
        list: [],
        challenges: "",
      },
      decisionMakers: {
        list: [],
        challenges: "",
      },
      financialAuthorities: {
        list: [],
        challenges: "",
      },
      blockers: {
        list: [],
        challenges: "",
      },
    });
    close();
  };

  const handleSubmit = async () => {
    setLoading(true);

    await updateStandardDoc("demographics", demoId, {
      data,
    });
    setLoading(false);
    handleClose();
  };

  console.log(data);

  if (!dataLoaded) {
    return <LinearProgress />;
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>Edit Demographic: {industryLabel}</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <Grid container spacing={2}>
            {personas.map((persona, index) => {
              return (
                <Grid item xs={12} md={6} key={index}>
                  <Stack spacing={1}>
                    <Typography>
                      <b>
                        {persona.label}{" "}
                        <LightTooltip title={persona.toolTip}>
                          <InfoRounded color="primary" />
                        </LightTooltip>
                      </b>
                    </Typography>
                    <Creatable
                      name={persona.name}
                      isMulti
                      value={data[persona.name].list}
                      {...selectFieldProps}
                      onChange={handlePersonaSelect}
                      placeholder={"Target Job Titles ..."}
                    />
                    <Creatable
                      name={persona.name}
                      isMulti
                      value={data[persona.name].excludes}
                      {...selectFieldProps}
                      onChange={handlePersonaExcludeSelect}
                      placeholder={"Exclude Job Titles ..."}
                    />
                    <TextField
                      {...textFieldProps}
                      label={"Challenges and Goals"}
                      name={persona.name}
                      value={data[persona.name].challenges}
                      onChange={handleChallengeText}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={loading}
          variant="contained"
          startIcon={<Save />}
          onClick={handleSubmit}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
