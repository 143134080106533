import { ArrowForward } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import { textFieldProps } from "../../shared/constants";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { countryCodes, selectFieldProps } from "../../shared/constants";

export default function NewSite({ open, close }) {
  const [data, setData] = useState({});
  const history = useHistory();
  const { createSite, GetOrgCollection } = useDb();
  const models = GetOrgCollection("models");
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setLoading(false);
    close();
  };

  const handleText = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const siteId = await createSite(data);
      history.push(`/site/${siteId}`);
      handleClose();
    } catch (err) {
      window.alert(err.message);
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>New Site</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            label="Site Name"
            name="name"
            onChange={handleText}
            {...textFieldProps}
            required
          />
          <TextField
            label="Site URL"
            name="url"
            type="url"
            onChange={handleText}
            {...textFieldProps}
            required
          />
          <TextField
            label="Company Name"
            name="companyName"
            onChange={handleText}
            {...textFieldProps}
            required
          />

          <Select
            options={countryCodes}
            {...selectFieldProps}
            placeholder="Primary country of operations ..."
            onChange={(v) =>
              setData({
                ...data,
                country: v.label,
              })
            }
            className="rmf-select"
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            endIcon={<ArrowForward />}
            disabled={loading}
          >
            Create Site
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
