import { Container } from "@mui/material";
import React from "react";
import BackLink from "../../../../shared/BackLink";

export default function ScheduleContent() {
  return (
    <div>
      <Container>
        <BackLink />
      </Container>
    </div>
  );
}
