import { Button } from "@mui/material";
import React, { useState } from "react";
import { useDb } from "../../../contexts/DatabaseContext";
import TrainingData from "./TrainingData";

export default function IwanTests() {
  const { iwanReviewFunction } = useDb();
  const [loading, setLoading] = useState(false);


  return (
    <div>
      <h1>Iwan Tests</h1>
      <TrainingData />
    </div>
  );
}
